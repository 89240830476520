import { createStore } from 'vuex';
import auth from './modules/auth';
import companies from './modules/companies';
import departments from './modules/departments';
import productionDocuments from './modules/productionDocuments';
import permissions from './modules/permissions';
import users from './modules/users';
import carts from './modules/carts';
import companySettings from './modules/companySettings';
import itemConfigurations from './modules/itemConfigurations';

export default createStore({
  state: {
    errors: [],
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    companies,
    departments,
    productionDocuments,
    permissions,
    users,
    carts,
    companySettings,
    itemConfigurations,
  },
});
