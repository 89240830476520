<template>
  <form @submit.prevent="updateRole" class="flex flex-col items-center">
    <div class="flex flex-col w-10/12 md:w-1/2">
      <label for="name" class="flex flex-col my-2 text-left">
        {{ $t('name') }}
        <input
          type="text"
          name="name"
          id="name"
          v-model="editableRole.name"
          required
        />
      </label>
      <div class="flex flex-col justify-center items-center my-2">
        <p>{{ $t('select-permission') }}</p>
        <div class="grid sm:grid-cols-2 sm:gap-x-24 w-fit">
          <label
            v-for="permission in permissions"
            :key="permission.id"
            :for="permission.getNameOption"
            class="flex flex-row-reverse justify-end my-2 text-left text-sm md:text-base"
          >
            {{ permission.name }}
            <input
              type="checkbox"
              :name="permission.name"
              :id="permission.name"
              :value="permission.name"
              v-model="editableRole.permissions"
            />
          </label>
        </div>
      </div>
      <submit-button>
        {{ $t('update-role') }}
      </submit-button>
    </div>
  </form>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import SubmitButton from '../base/SubmitButton.vue';

export default {
  components: {
    SubmitButton,
  },
  props: {
    role: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editableRole: {
        id: this.role.id,
        name: this.role.name,
        permissions: this.role.permissions.map((permission) => permission.name),
      },
    };
  },
  computed: {
    ...mapGetters('permissions', ['permissions']),
  },
  methods: {
    ...mapActions({
      getPermissions: 'permissions/getPermissions',
      patchRole: 'permissions/patchRole',
    }),
    updateRole() {
      this.patchRole(this.editableRole);
      this.$emit('role-has-been-updated');
    },
  },
  created() {
    this.getPermissions();
  },
};
</script>
