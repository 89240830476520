import axios from 'axios';

export default {
  namespaced: true,

  state: {
    users: [],
    userRoles: [],
  },

  getters: {
    users: (state) => state.users,
  },

  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
  },

  actions: {
    async getUsers({ commit }) {
      const { data } = await axios.get('/api/users');

      commit('setUsers', data);
    },
    async postUser({ dispatch }, user) {
      const { data } = await axios.post('/api/users', user);

      dispatch('getUsers');

      return data;
    },
    async patchUser({ dispatch }, { id, data }) {
      await axios.patch(`/api/users/${id}`, data);

      dispatch('getUsers');
    },
    async getUserCompanies({ dispatch }, username) { //eslint-disable-line 
      const { data } = await axios.get(`/api/user/${username}/companies`);
      return data;
    },
    async postAttachCompanies({ dispatch }, { id, companiesList }) { //eslint-disable-line 
      await axios.post(`/api/user/${id}/companies`, { companies: companiesList });
    },
    async getUserRoles({ dispatch }, id) { //eslint-disable-line
      const { data } = await axios.get(`/api/user/${id}/roles`);

      return data;
    },
    async postAttachRoles({ dispatch }, { id, roles }) { //eslint-disable-line
      await axios.post(`/api/user/${id}/roles`, { roles });
    },
  },
};
