<template>
  <div class="w-full mx-auto mt-12">
    <h1 class="w-1/2 mx-auto my-5 text-2xl md:text-3xl font-bold uppercase
    text-aitmes-blue dark:text-white">
      {{ $t('select-dep') }}
    </h1>
    <div class="w-full mx-auto flex flex-wrap justify-center">
      <button
        v-for="department in departments" :key="department.id"
        class="flex-initial basis-2/3 md:basis-1/3
        xl:basis-1/4 shadow-lg bg-white text-gray-600 m-4 p-4 rounded-xl
        border-t-8 border-t-aitmes-purple duration-200 ease-in
        bg-[length:100%_0] bg-no-repeat dark:bg-slate-600 dark:text-white"
        :class="{ active: department.isActive }" @click="toggleClassActive(department)"
      >
        <div
          class="grid grid-cols-1 lg:grid-cols-12 justify-between cursor-pointer"
          @click="onDepartmentSelection(department.id)"
          @keypress.prevent="onDepartmentSelection(department.id)"
        >
          <div class="col-span-10 text-left relative">
            <p class="mb-1">{{ department.slug }}</p>
            <p class="mb-1">{{ department.name }}</p>
          </div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      selectedDepartment: 0,
    };
  },
  computed: {
    ...mapGetters('departments', ['departments']),
  },
  methods: {
    ...mapActions({
      getDepartments: 'departments/getDepartments',
      setDepartment: 'productionDocuments/setDepartment',
    }),
    enrichedDepartment(departments) {
      departments.map((department) => ({ ...department, isActive: false }));
    },
    onDepartmentSelection(departmentId) {
      this.selectedDepartment = departmentId;
      this.setDepartment(departmentId);
    },
    toggleClassActive(department) {
      // eslint-disable-next-line no-restricted-syntax
      for (const dept of this.departments) {
        dept.isActive = false;
      }
      // eslint-disable-next-line no-param-reassign
      department.isActive = true;
    },
  },
  async created() {
    await this.getDepartments();
    this.enrichedDepartment(this.departments);
  },

};
</script>

<style scoped>
.active {
  transform: scaleX(1);
  background-image: linear-gradient(#A93365, #A93365);
  background-size: 100% 100%;
  color: #fff;
}
</style>
