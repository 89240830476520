<template>
  <div class="m-5 w-1/2">
    <div class="flex bg-aitmes-blue dark:bg-aitmes-blue/80 justify-center rounded-t-2xl p-4
    text-white font-black text-md uppercase">
      <slot name="title"/>
    </div>
    <div class="flex flex-col bg-white items-start rounded-b-2xl p-6 shadow-lg
    text-gray-600 space-y-2 capitalize dark:text-white dark:bg-slate-600">
      <slot name="links"/>
    </div>
  </div>
</template>
