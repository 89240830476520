export default {
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I segreti della tua produzione svelati"])},
  "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aitmes® è un prodotto de The Nonsense Factory 2015 - "])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produzione"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aziende"])},
  "add-company": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi una nuova azienda"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi azienda"])}
  },
  "update-company": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica azienda"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica azienda"])}
  },
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
  "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome azienda"])},
  "companies-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista aziende"])},
  "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slug"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
  "cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cap"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
  "e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "department-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome reparto"])},
  "add-department": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un nuovo reparto"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi reparto"])}
  },
  "update-department": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica reparto"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica reparto"])}
  },
  "panthera-dep-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice reparto in Panthera"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruoli"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
  "users-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista utenti"])},
  "add-new-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un nuovo utente"])},
  "add-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi utente"])},
  "update-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna password"])},
  "update-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna utente"])},
  "edit-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica utente"])},
  "change-password-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia password per l'utente:"])},
  "add-new-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un nuovo ruolo"])},
  "add-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi ruolo"])},
  "roles-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista ruoli"])},
  "update-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna ruolo"])},
  "edit-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica ruolo"])},
  "select-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona permessi (almeno uno)"])},
  "password-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma password"])},
  "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivalo per questa azienda (almeno una)"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inizia"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successivo"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
  "part-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice articolo"])},
  "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. di serie"])},
  "action-serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. di serie meccaniche"])},
  "select-dep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il tuo reparto"])},
  "saved-panthera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvato su Panthera?"])},
  "panthera-doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Panthera doc"])},
  "add-panthera-doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Panthera doc"])},
  "production-doc-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista documenti di produzione"])},
  "scan-enter-item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scansionare il codice a barre o inserire i dettagli dell'oggetto nei campi sottostanti"])},
  "departments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reparti"])},
  "production-doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenti di produzione"])},
  "production-data-collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccolta dati della produzione"])},
  "excel-export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esportare Excel righe Doc. Produzione "])},
  "add-dep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un reparto"])},
  "dep-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista reparti"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare"])},
  "update-dep-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il reparto è stato aggiornato"])},
  "add-dep-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il reparto è stato aggiunto"])},
  "update-company-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'azienda è stata aggiornata"])},
  "add-company-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'azienda è stata aggiunta"])},
  "update-production-doc-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riga documenti di produzione aggiornata"])},
  "add-panthera-doc-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. documento Panthera aggiunto"])},
  "update-role-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il ruolo è stato aggiornato"])},
  "add-role-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il ruolo è stato aggiunto"])},
  "update-user-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utente è stato aggiornato"])},
  "add-user-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utente è stato aggiunto"])},
  "update-password-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password è stata cambiata"])},
  "update-row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna riga"])},
  "add-production-doc-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il documento di produzione è stato creato"])},
  "api-errors": {
    "not-authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Autorizzato"])}
  },
  "company-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caratteristiche"])},
  "item-configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurazioni"])},
  "update-config": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica configurazione"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica configurazione"])}
  },
  "config_cod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice configurazione"])},
  "config_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
  "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice articolo"])},
  "update-con-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configurazione è stata modificata"])},
  "add-config": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi una nuova configurazione"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi una nuova configurazione"])}
  },
  "conf-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista configurazioni"])},
  "item_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item ID"])},
  "management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestione"])},
  "update-setting": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica impostazione"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica impostazione"])}
  },
  "company-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazione"])},
  "company-setting-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore di default"])},
  "update-company-setting-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'impostazione è stata modificata"])}
}