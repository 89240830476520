<template>
  <div class="flex justify-end">
    <div class="flex flex-col items-center w-10/12 lg:w-11/12">
      <production-documents-list></production-documents-list>
    </div>
  </div>
</template>

<script>
import ProductionDocumentsList from '@/components/production-documents/ProductionDocumentsList.vue';

export default {
  components: {
    ProductionDocumentsList,
  },
};
</script>
