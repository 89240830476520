<template>
  <div class="mx-auto flex flex-col items-center">
    <user-row
      v-for="user in users"
      :key="user.id"
      :user="user"
      class="shadow-lg bg-white text-gray-600 m-2 py-2 px-6 rounded-xl
      border-t-4 border-t-aitmes-purple dark:bg-slate-600 dark:text-white"
    >
    </user-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserRow from '@/components/users/UserRow.vue';

export default {
  components: {
    UserRow,
  },
  computed: {
    ...mapGetters('users', ['users']),
  },
  methods: {
    ...mapActions('users', ['getUsers']),
  },
  created() {
    this.getUsers();
  },
};

</script>
