<template>
  <div>
    <nav-bar v-show="authenticated"/> <!-- v-show authenticated -->
    <router-view/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import NavBar from '@/components/NavBar.vue';

export default {
  components: {
    NavBar,
  },
  computed: {
    ...mapGetters('auth', ['authenticated']),
  },
  created() {
    if (!localStorage.getItem('theme')) {
      const soTheme = window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light';
      localStorage.setItem('theme', soTheme);
    }
  },
  mounted() {
    const lsTheme = localStorage.getItem('theme');
    const element = document.body;
    element.removeAttribute('class');
    element.classList.add(lsTheme);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  @apply min-h-screen bg-white dark:bg-slate-700
}

label input {
  @apply border rounded-lg p-2 mt-1 font-thin bg-transparent text-sm lg:text-base
  focus:outline-none focus:ring-2 focus:ring-aitmes-purple/80
}

input[type="checkbox"] {
  @apply  my-1 mr-2 w-1 h-1 rounded appearance-none checked:bg-aitmes-purple
          checked:ring-4 checked:ring-inset checked:ring-white dark:checked:ring-slate-700
}
</style>
