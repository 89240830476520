<template>
  <form @submit.prevent="onProductionDocumentRowUpdate"
  class="flex flex-col">
    <div class="grid grid-cols-2 justify-start my-4 mx-auto w-11/12 md:w-3/4 gap-4">
      <label for="qty" class="flex flex-col m-2 text-left">
        {{ $t('quantity') }}
        <input
          type="text"
          name="qty"
          v-model="updatingRow.qty"
        />
      </label>
      <label for="partNumber" class="flex flex-col m-2 text-left">
        {{ $t('part-number') }}
        <input
          type="text"
          name="partNumber"
          v-model="updatingRow.part_number"
        />
      </label>
      <label for="configuration" class="flex flex-col m-2 text-left">
        {{ $t('configuration') }}
        <input
          type="text"
          v-model="updatingRow.config_code"
        />
      </label>
      <label for="serial" class="flex flex-col m-2 text-left">
        {{ $t('serial') }}
        <input
          type="text"
          name="serial"
          v-model="updatingRow.serial"
        />
      </label>
      <label for="action_serial" class="flex flex-col m-2 text-left">
        {{ $t('action-serial') }}
        <input
          type="text"
          name="action_serial"
          v-model="updatingRow.action_serial_no"
        />
      </label>
    </div>
    <submit-button>
      {{ $t('save') }}
    </submit-button>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import SubmitButton from '../base/SubmitButton.vue';

export default {
  components: {
    SubmitButton,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      updatingRow: this.row,
    };
  },
  methods: {
    ...mapActions('productionDocuments', ['putProductionDocumentRows']),
    async onProductionDocumentRowUpdate() {
      const data = {
        qty: this.updatingRow.qty,
        part_number: this.updatingRow.part_number,
        config_code: this.updatingRow.config_code,
        serial: this.updatingRow.serial,
        action_serial_no: this.updatingRow.action_serial_no,
      };

      await this.putProductionDocumentRows({ id: this.updatingRow.id, data });
      this.$emit('production-document-row-has-been-updated');
    },
  },
};
</script>
