<template>
  <form @submit.prevent="addCompany" class="flex flex-col">
    <div class="grid grid-cols-2 justify-center my-4 w-11/12 md:w-3/4 mx-auto">
      <label for="slug" class="flex flex-col m-2 text-left">
        {{ $t('slug') }}
        <input
          type="text"
          name="slug"
          id="slug"
          v-model="company.slug"
          required
        />
      </label>
      <label for="name" class="flex flex-col m-2 text-left">
        {{ $t('company-name') }}
        <input
          type="text"
          name="name"
          id="name"
          v-model="company.name"
          required
        />
      </label>
      <label for="address" class="flex flex-col m-2 text-left">
        {{ $t('address') }}
        <input
          type="text"
          name="address"
          id="address"
          v-model="company.address"
        />
      </label>
      <label for="city" class="flex flex-col m-2 text-left">
        {{ $t('city') }}
        <input
          type="text"
          name="city"
          id="city"
          v-model="company.city"
        />
      </label>
      <label for="cap" class="flex flex-col m-2 text-left">
        {{ $t('cap') }}
        <input
          type="text"
          name="cap"
          id="cap"
          v-model="company.cap"
        />
      </label>
      <label for="state" class="flex flex-col m-2 text-left">
        {{ $t('state') }}
        <input
          type="text"
          name="state"
          id="state"
          v-model="company.state"
        />
      </label>
      <label for="country" class="flex flex-col m-2 text-left">
        {{ $t('country') }}
        <input
          type="text"
          name="country"
          id="country"
          v-model="company.country"
        />
      </label>
      <label for="email" class="flex flex-col m-2 text-left">
        {{ $t('e-mail') }}
        <input
          type="email"
          name="email"
          id="email"
          required
          v-model="company.email"
        />
      </label>
    </div>
    <submit-button>
      {{ $t('add-company.btn') }}
    </submit-button>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import SubmitButton from '../base/SubmitButton.vue';

export default {
  components: {
    SubmitButton,
  },
  data() {
    return {
      company: {
        slug: '',
        name: '',
        address: '',
        city: '',
        state: '',
        country: '',
        email: '',
      },
    };
  },
  methods: {
    ...mapActions('companies', ['postCompany']),
    addCompany() {
      this.postCompany(this.company);
      this.$emit('company-has-been-added');
    },
  },
};
</script>
