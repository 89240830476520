<template>
  <div>
    <li class="grid grid-cols-5 gap-x-6 mb-3 items-start">
      <div class="col-span-1 flex justify-end">
        <p class="w-fit h-fit px-2 py-0.5 rounded text-white dark:bg-opacity-70"
      :class="bgColor">
        {{type}}
      </p>
      </div>
      <p class="font-thin text-black col-span-4 dark:text-white">
        {{description}}
      </p>
    </li>
  </div>
</template>

<script>

export default {
  props: ['change'],
  data() {
    return {
      description: this.change.description,
      type: this.change.type,
      bgColors: {
        fix: 'bg-red-500',
        new: 'bg-green-500',
        updated: 'bg-cyan-500',
      },
    };
  },

  computed: {
    bgColor() {
      return this.bgColors[this.change.type];
    },
  },
};
</script>
