import axios from 'axios';

export default {
  namespaced: true,

  state: {
    permissions: [],
    roles: [],
  },

  getters: {
    permissions: (state) => state.permissions,
    roles: (state) => state.roles,
  },

  mutations: {
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setUserRoles(state, userRoles) {
      state.userRoles = userRoles;
    },
  },
  actions: {
    async getPermissions({ commit }) {
      const { data } = await axios.get('/api/permissions');

      commit('setPermissions', data);
    },
    async getRoles({ commit }) {
      const { data } = await axios.get('/api/roles');

      commit('setRoles', data);
    },
    async postRole({ dispatch }, role) {
      await axios.post('/api/roles', role);

      dispatch('getRoles');
    },
    async patchRole({ dispatch }, role) {
      await axios.patch(`/api/roles/${role.id}`, role);

      dispatch('getRoles');
    },
    async postAttachRoles({ dispatch }, { id, roles }) {
      await axios.post(`/api/user/${id}/roles`, roles);

      dispatch('getUserRoles');
    },
  },
};
