<template>
  <form @submit.prevent="updateUser" class="flex flex-col items-center">
    <div class="flex flex-col w-10/12 md:w-2/3 my-4">
      <label for="password" class="flex flex-col my-2 text-left">
        {{ $t('Password') }}
        <input
          type="password"
          name="password"
          id="password"
          v-model="password"
          required
        />
      </label>
      <label for="password-confirmation" class="flex flex-col my-2 text-left">
        {{ $t('password-confirmation') }}
        <input
          type="password"
          name="password-confirmation"
          id="password-confirmation"
          v-model="passwordConfirmation"
          required
        />
      </label>
      <submit-button>
        {{ $t('update-password') }}
      </submit-button>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import SubmitButton from '../base/SubmitButton.vue';

export default {
  components: {
    SubmitButton,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      password: '',
      passwordConfirmation: '',
    };
  },
  methods: {
    ...mapActions('users', ['patchUser']),
    updateUser() {
      this.patchUser({ id: this.userId, data: { password: this.password } });
      this.$emit('password-has-been-changed');
    },
  },
};
</script>
