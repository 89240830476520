import axios from 'axios';

export default {
  namespaced: true,

  state: {
    itemConfigurations: [],
  },

  getters: {
    itemConfigurations: (state) => state.itemConfigurations,
  },

  mutations: {
    setItemConfigurations(state, itemConfigurations) {
      state.itemConfigurations = itemConfigurations;
    },
  },

  actions: {
    async getItemConfigurations({ commit }) {
      const { data } = await axios.get('/api/item-configurations');

      commit('setItemConfigurations', data);
    },
    async postItemConfiguration({ dispatch }, itemConfiguration) {
      await axios.post('/api/item-configurations', itemConfiguration);

      dispatch('getItemConfigurations');
    },
    async putItemConfiguration({ dispatch }, { id, data }) {
      await axios.put(`/api/item-configurations/${id}`, data);

      dispatch('getItemConfigurations');
    },
  },
};
