<template>
  <div class="w-full">
    <ul class="w-full mx-auto flex flex-wrap justify-center">
      <li
        v-for="department in departments" :key="department.id"
        class="flex-initial basis-2/3 md:basis-1/3
        xl:basis-1/4 shadow-lg bg-white text-gray-600 m-4 p-4 rounded-xl
        border-t-8 border-t-aitmes-purple dark:bg-slate-600 dark:text-white"
      >
        <department-row :department="department"></department-row>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DepartmentRow from '@/components/departments/DepartmentRow.vue';

export default {
  components: {
    DepartmentRow,
  },
  computed: {
    ...mapGetters('departments', ['departments']),
  },
  methods: {
    ...mapActions('departments', ['getDepartments']),
  },
  created() {
    this.getDepartments();
  },
};
</script>
