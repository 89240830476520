<!-- eslint-disable max-len -->
<template>
  <div class="h-screen w-fit fixed fill-white">
    <div class="bg-aitmes-blue flex h-2/3 dark:bg-opacity-70">
      <div class="mx-3 md:mx-4 mt-12 flex flex-col items-center">
        <router-link to="/dashboard">
          <svg class="w-12 h-12 md:w-14 md:h-14" id="Group_85" data-name="Group 85" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 70">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_119" data-name="Rectangle 119" width="206.056" height="69.645" transform="translate(0 0)" fill="none"/>
              </clipPath>
            </defs>
            <g id="Group_84" data-name="Group 84" clip-path="url(#clip-path)">
              <path id="Path_32" data-name="Path 32" d="M38.4,11.048l-3.914-8.7a3.669,3.669,0,0,0-6.849,0L.409,62.858c-1.272,2.827.579,6.153,3.425,6.153h8.483Z" fill="rgba(255,255,255)"/>
              <path id="Path_33" data-name="Path 33" d="M61.953,62.858,54.548,46.4s-.006-.008-.011-.008h-18.4a1.755,1.755,0,0,1-.122-3.5H52.917a.044.044,0,0,0,.039-.031L40.3,14.745c-.022-.014-.049-.028-.062,0L15.853,68.937a.051.051,0,0,0,.041.074H58.528c2.846,0,4.7-3.326,3.425-6.153" fill="rgba(255,255,255)"/>
            </g>
          </svg>
        </router-link>
        <router-link to="/changelog"> <p class="w-full flex justify-end text-xs md:text-sm font-thin text-white my-2">{{version}}</p> </router-link>
        <div class="font-black text-white md:text-lg">
          {{ activeCompany }}
        </div>
        <button class="dark-mode" @click="toggleDarkMode()">
        </button>
      </div>
    </div>
    <div class="bg-aitmes-purple h-1/3 flex dark:bg-opacity-70">
      <div class="flex flex-col justify-around m-auto h-full">
        <div>
          <svg class="px-0.5 pt-1 w-10 h-10 rounded-md md:w-12 md:h-12 xl:w-14 xl:h-14 md:rounded-xl bg-white bg-opacity-40 hover:bg-opacity-80 active:bg-opacity-100 stroke-white hover:stroke-aitmes-purple" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="57" height="57" viewBox="0 0 57 57">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_71" data-name="Rectangle 71" width="57" height="57" rx="17" transform="translate(5 5)" fill="rgba(255,255,255,0.5)"/>
              </clipPath>
            </defs>
            <g id="Mask_Group_41" data-name="Mask Group 41" transform="translate(-5 -5)" clip-path="url(#clip-path)">
              <g id="Group_30" data-name="Group 30" transform="translate(7.37 12.37)">
                <g id="Group_29" data-name="Group 29" transform="translate(0 0)">
                  <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(13.63 2.629)" fill="none" stroke-width="4">
                    <circle cx="12.5" cy="12.5" r="12.5" stroke="none"/>
                    <circle cx="12.5" cy="12.5" r="10.5" fill="none"/>
                  </g>
                  <g id="Rectangle_112" data-name="Rectangle 112" transform="translate(3.63 29.63)" fill="none" stroke-width="5">
                    <rect width="45" height="38" rx="14" stroke="none"/>
                    <rect x="2.5" y="2.5" width="40" height="33" rx="11.5" fill="none"/>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div>
          <svg class="p-1 w-10 h-10 rounded-md md:w-12 md:h-12 xl:w-14 xl:h-14 md:rounded-xl bg-white bg-opacity-40 hover:bg-opacity-80 active:bg-opacity-100 stroke-white hover:stroke-aitmes-purple" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="57" height="57" viewBox="0 0 57 57">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_114" data-name="Rectangle 114" width="57" height="57" rx="17" transform="translate(14.322)" fill="rgba(255,255,255,0.5)"/>
              </clipPath>
              <clipPath id="clip-path-2">
                <rect id="Rectangle_115" data-name="Rectangle 115" width="46" height="42.667" transform="translate(-3 -3)" fill="none" stroke-width="3"/>
              </clipPath>
            </defs>
            <g id="Mask_Group_44" data-name="Mask Group 44" transform="translate(-14.322)" clip-path="url(#clip-path)">
              <g id="Group_75" data-name="Group 75" transform="translate(22.822 12)">
                <g id="Group_74" data-name="Group 74" transform="matrix(0.966, -0.259, 0.259, 0.966, -4.064, 5.801)" clip-path="url(#clip-path-2)">
                  <path id="Path_19" data-name="Path 19" d="M9.759,92.166H7.812A2.073,2.073,0,0,1,5.9,89.293l4.286-10.24h6.441L11.671,90.894A2.073,2.073,0,0,1,9.759,92.166Z" transform="translate(-4.141 -57.056)" fill="none" stroke-miterlimit="10" stroke-width="3.5"/>
                  <path id="Path_20" data-name="Path 20" d="M17.775,38.135H4.073A2.073,2.073,0,0,1,2,36.063V24.433A2.073,2.073,0,0,1,4.073,22.36h13.7Z" transform="translate(-1.443 -16.138)" fill="none" stroke-miterlimit="10" stroke-width="3.5"/>
                  <path id="Path_21" data-name="Path 21" d="M79.279,29.039l-20.46-5.564V7.693L79.274,2.075a2.073,2.073,0,0,1,2.622,2V27.039A2.073,2.073,0,0,1,79.279,29.039Z" transform="translate(-42.452 -1.443)" fill="none" stroke-miterlimit="10" stroke-width="3.5"/>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <logout-button></logout-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LogoutButton from '@/components/LogoutButton.vue';

export default {
  components: {
    LogoutButton,
  },
  computed: {
    ...mapGetters('auth', ['activeCompany']),
  },
  methods: {
    toggleDarkMode() {
      const lsTheme = localStorage.getItem('theme') === 'dark' ? 'light' : 'dark';
      const element = document.body;
      element.removeAttribute('class');
      element.classList.add(lsTheme);
      localStorage.setItem('theme', lsTheme);
    },
  },
  data() {
    return {
      version: process.env.VUE_APP_RELEASE,
    };
  },
};
</script>

<style scoped>
.dark-mode {
  box-shadow: inset 0 -1px 10px #1c3d57;
  @apply w-8 h-8 p-1.5 rounded-full cursor-pointer flex items-center justify-center mt-2
  bg-gradient-to-b from-gray-800 dark:bg-gradient-to-t dark:from-gray-100/60
  before:bg-[url('../assets/images/moon-icon.png')] before:bg-contain
  dark:before:bg-[url('../assets/images/sun-icon.png')] before:w-full before:h-full
}
</style>
