<!-- eslint-disable max-len -->
<template>
    <div class="w-full flex justify-center">
      <table class="flex flex-col w-3/4 xl:w-3/5 text-sm lg:text-base text-left shadow-lg rounded-2xl overflow-hidden pb-4">
        <tr class="grid grid-cols-7 w-full bg-aitmes-blue justify-center rounded-t-2xl p-4
          text-white font-black text-md uppercase">
          <th class="col-span-2 px-2">ITEM ID</th>
          <th class="col-span-2 px-2">CONFIGURATION</th>
          <th class="col-span-2 px-2">DESCRIPTION</th>
        </tr>
        <tr v-for="itemConfiguration in itemConfigurations" :key="itemConfiguration.id">
          <item-configuration-row :itemConfiguration="itemConfiguration"/>
        </tr>
      </table>
    </div>
</template>

<script>
import ItemConfigurationRow from '@/components/item-configurations/ItemConfigurationRow.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    ItemConfigurationRow,
  },
  computed: {
    ...mapGetters('itemConfigurations', ['itemConfigurations']),
  },
  methods: {
    ...mapActions('itemConfigurations', ['getItemConfigurations']),
  },
  created() {
    this.getItemConfigurations();
  },
};
</script>
