import axios from 'axios';

export default {
  namespaced: true,

  state: {
    companies: [],
  },

  getters: {
    companies: (state) => state.companies,
  },

  mutations: {
    setCompanies(state, companies) {
      state.companies = companies;
    },
  },

  actions: {
    async getCompanies({ commit }) {
      const { data } = await axios.get('/api/companies');

      commit('setCompanies', data);
    },
    async postCompany({ dispatch }, company) {
      await axios.post('/api/companies', company);

      dispatch('getCompanies');
    },
    async patchCompany({ dispatch }, { id, data }) {
      await axios.patch(`/api/companies/${id}`, data);

      dispatch('getCompanies');
    },
  },

};
