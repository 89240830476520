<template>
  <form @submit.prevent="addConfiguration" class="flex flex-col">
    <div class="grid grid-cols-2 justify-center my-4 w-11/12 md:w-3/4 mx-auto">
      <label for="item_id" class="flex flex-col m-2 text-left">
        {{ $t('item_id') }}
        <input
            type="text"
            name="item_id"
            id="item_id"
            v-model="itemConfigurations.item_id"
            required
        />
      </label>
      <label for="config_cod" class="flex flex-col m-2 text-left">
        {{ $t('config_cod') }}
        <input
            type="text"
            name="config_cod"
            id="config_cod"
            v-model="itemConfigurations.config_cod"
            required
        />
      </label>
      <label for="config_description" class="flex flex-col m-2 text-left">
        {{ $t('config_description') }}
        <input
            type="text"
            name="config_description"
            id="config_description"
            v-model="itemConfigurations.config_description"
            required
        />
      </label>
    </div>
    <submit-button>
      {{ $t('add-config.btn') }}
    </submit-button>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import SubmitButton from '../base/SubmitButton.vue';

export default {
  components: {
    SubmitButton,
  },
  data() {
    return {
      itemConfigurations: {
        item_id: '',
        config_cod: '',
        config_description: '',
      },
    };
  },
  methods: {
    ...mapActions('itemConfigurations', ['postItemConfiguration']),
    async addConfiguration() {
      await this.postItemConfiguration(this.itemConfigurations);
      this.$emit('config-has-been-added');
    },
  },
};
</script>
