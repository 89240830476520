<template>
  <div class="w-full">
    <ul class="w-full mx-auto flex flex-wrap justify-center">
      <li v-for="company in companies" :key="company.id" class="flex-initial basis-2/3 md:basis-1/3
      xl:basis-1/4 shadow-lg bg-white text-gray-600 m-4 p-4 rounded-xl
      border-t-8 border-t-aitmes-purple dark:bg-slate-600 dark:text-white">
        <company-row :company="company"></company-row>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CompanyRow from '@/components/companies/CompanyRow.vue';

export default {
  components: {
    CompanyRow,
  },
  computed: {
    ...mapGetters('companies', ['companies']),
  },
  methods: {
    ...mapActions('companies', ['getCompanies']),
  },
  created() {
    this.getCompanies();
  },
};
</script>
