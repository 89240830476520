<template>
  <div class="flex flex-col w-10/12 md:w-11/12 h-screen justify-center">
    <button
      class="w-fit mx-auto border-4 border-aitmes-purple text-aitmes-purple
    dark:text-white md:px-20 md:py-6 px-10
      py-3 rounded-xl ring-8 ring-aitmes-purple/50 font-bold uppercase text-4xl
      md:text-9xl hover:bg-aitmes-purple hover:dark:text-slate-700
      hover:text-white transition-color duration-500"
      @click="openDepartmentSelection"
      v-show="isStep0"
    >
      {{ $t('start') }}
    </button>
    <component
      :is="activeComponent"
      @production-document-has-been-saved="onProductionDocumentHasBeenSaved"
    />
      <button
        class="rounded-lg w-fit mt-5 mx-auto border-2 border-aitmes-blue text-aitmes-blue
        dark:border-aitmes-purple dark:text-white px-6 py-2 hover:dark:bg-aitmes-purple
        hover:dark:ring-0 hover:dark:text-slate-700 text-xl font-bold hover:ring-4 ring-aitmes-blue
        ring-opacity-25"
        v-show="activeComponent == 'DepartmentSelector'"
        :disabled="isDepartmentIdZero"
        @click="openRowsColletion"
        >
        {{ $t('next') }}
      </button>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import DepartmentSelector from '@/components/production-documents/DepartmentSelector.vue';
import RowCollection from '@/components/production-documents/RowCollection.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    DepartmentSelector,
    RowCollection,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      isStep0: true,
      activeComponent: '',
    };
  },
  computed: {
    ...mapGetters('productionDocuments', ['newProductionDocumentDepartmentId']),
    isDepartmentIdZero() {
      return !this.newProductionDocumentDepartmentId;
    },
  },

  methods: {
    openDepartmentSelection() {
      this.activeComponent = 'DepartmentSelector';
      this.isStep0 = false;
    },
    openRowsColletion() {
      this.isStep0 = false;
      this.activeComponent = 'RowCollection';
    },
    onProductionDocumentHasBeenSaved() {
      this.activeComponent = '';
      this.isStep0 = true;
      this.toast.success(this.$t('add-production-doc-alert'));
    },
  },
};
</script>
