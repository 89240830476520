 <!--eslint-disable--> 
<template>
  <div>
    <div class="w-full flex items-center">
        <p class="text-right uppercase mr-2 w-8">Link</p>
      <div v-for="cartBox in cartBoxes" :key="cartBox.id" class="w-12 lg:w-20 border-b-5 border-x-2 lg:border-b-8 lg:border-x-3 xl:border-b-10 xl:border-x-5 border-zinc-400 pt-3 xl:pt-6 bg-zinc-700 mx-0.5 lg:mx-1 rounded"><!--eslint-disable-line-->         
        <p class="font-bold bg-white border-x lg:border-x-2 border-zinc-400 text-xs lg:text-sm xl:text-base"> <!--eslint-disable-line--> 
          {{cartBox.label}}
        </p>
      </div>
    </div>
    <label for="quantity" class="flex items-center">
        <p class="text-right uppercase mr-2 w-8">Qty</p>
        <input type="number" class="w-12 lg:w-20 border mx-0.5 lg:mx-1 my-1 border-zinc-400"
        v-for="cartBox in cartBoxes" :key="cartBox.id" :value="cartBox.default_qty"  required />
    </label>
  </div>
</template>

<script>
export default {
  props: ['cartBoxes', 'colNumber', 'rowNumber'],
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
