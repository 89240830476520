<template>
  <div class="w-3/4 lg:w-4/5 xl:w-3/5 mx-auto flex flex-col items-end lg:items-center">
    <log-card v-for="log in logs" :key="log" :log="log"/>
  </div>
</template>

<script>
import LogsData from '@/data/changelog.json';
import LogCard from '../components/changelog/LogCard.vue';

export default {
  props: ['log'],

  components: {
    LogCard,
  },

  data() {
    return {
      logs: LogsData.releases,
    };
  },
};
</script>
