<template>
  <form @submit.prevent="updateConfig" class="flex flex-col">
    <div class="grid grid-cols-2 justify-start my-4 mx-auto w-11/12 md:w-3/4">
      <label for="item_id" class="flex flex-col m-2 text-left">
        {{ $t('item_id') }}
        <input
          type="text"
          name="item_id"
          id="item_id"
          v-model="editableConfig.item_id"
          required
          disabled
        />
      </label>

      <label for="config_cod" class="flex flex-col m-2 text-left">
        {{ $t('config_cod') }}
        <input
          type="text"
          name="config_cod"
          id="config_cod"
          v-model="editableConfig.config_cod"
          required
          disabled
        />
      </label>

      <label for="config_description" class="flex flex-col m-2 text-left">
        {{ $t('config_description') }}
        <input
          type="text"
          name="config_description"
          id="config_description"
          v-model="editableConfig.config_description"
          required
        />
      </label>
    </div>
    <submit-button>
        {{ $t('update-config.btn') }}
    </submit-button>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import SubmitButton from '../base/SubmitButton.vue';

export default {
  components: {
    SubmitButton,
  },
  props: {
    itemConfiguration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editableConfig: {
        item_id: this.itemConfiguration.item_id,
        config_cod: this.itemConfiguration.config_cod,
        config_description: this.itemConfiguration.config_description,
        company: this.itemConfiguration.company,
      },
    };
  },
  methods: {
    ...mapActions('itemConfigurations', ['putItemConfiguration']),
    updateConfig() {
      this.putItemConfiguration({ id: this.itemConfiguration.id, data: this.editableConfig });
      this.$emit('config-has-been-updated');
    },
  },
};
</script>
