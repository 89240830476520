<!-- eslint-disable max-len -->
<template>
  <transition name="fade">
    <div class="fixed inset-0 overflow-x-hidden overflow-y-auto z-50 h-full" v-if="show">
      <div class="fixed inset-0 bg-gray-800 opacity-50 z-10
      backdrop-blur-2xl backdrop-contrast-200" @click="closeModal" @keypress="closeModal"/>

      <div
        class="relative w-11/12 md:4/5 lg:w-3/5 font-bold bg-white dark:bg-slate-600 rounded-xl mt-24 mx-auto flex flex-col z-20 h-auto dark:text-white">
        <div
        class="flex items-center justify-between md:justify-center relative p-5 w-full text-lg text-white bg-aitmes-purple rounded-t-xl">
          <slot name="header"/>
          <button type="button" class="absolute top-0 right-0 mr-8 lg:mr-14 h-full items-center" @click="closeModal()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="w-2 md:w-3">
              <path
                fill="#fff"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
              ></path>
            </svg>
          </button>
        </div>

        <div class="py-5 md:p-5 overflow-auto flex flex-col md:flex-col items-stretch">
          <slot name="body"/>
        </div>

        <div class="px-1 md:px-5">
          <slot name="footer"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CustomModal',
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    openModal() {
      this.show = true;
      document.querySelector('body').classList.add('overflow-hidden');
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
