<!-- eslint-disable max-len -->
<template>
  <div>
    <custom-modal ref="addPantheraNo">
      <template v-slot:header>
        <h1>{{ $t('saved-panthera') }}</h1>
      </template>
      <template v-slot:body>
        <form class="flex flex-col" @submit.prevent="addPantheraDocNo">
          <label for="panthera_doc_no" class="flex flex-col m-2 text-left">
            {{ $t('panthera-doc') }}
            <input
              type="panthera_doc_no"
              name="panthera_doc_no"
              id="panthera_doc_no"
              v-model="pantheraDocNo"
              required
            />
          </label>
          <submit-button>
            {{ $t('add-panthera-doc') }}
          </submit-button>
        </form>
      </template>
    </custom-modal>
    <custom-modal ref="updateRow">
      <template v-slot:header>
        <h1>{{ $t('update-row') }}</h1>
      </template>
      <template v-slot:body>
        <update-production-document-row
          :row="this.activeRow"
          @production-document-row-has-been-updated="onProductionDocumentRowHasBeenUpdated"
        />
      </template>
    </custom-modal>
    <div class="flex p-3 text-center space-x-2 text-xs lg:text-sm 2xl:text-base items-center">
      <div class="w-1/12">
        {{ document.year}}
      </div>
      <div class="w-1/6">
        {{ document.document_number }}
      </div>
      <div class="w-1/3">
        {{ document.department.name }}
      </div>
      <div class="w-1/4">
        {{ document.user.name }}
      </div>
      <div class="w-1/6">
        {{ formattedDocumentDate }}
      </div>
      <div class="w-1/5">
        {{ document.panthera_document_id }}
      </div>
      <div class="flex justify-center w-1/12">
        <button class="rounded text-white bg-aitmes-blue font-bold text-sm lg:text-lg px-2 dark:bg-aitmes-purple dark:bg-opacity-75 bg-opacity-75 hover:bg-opacity-100"
        @click="$refs.addPantheraNo.openModal">
        &plus;
        </button>
      </div>
      <div class="flex justify-center w-1/12">
        <button class="text-aitmes-blue font-bold text-xl md:text-3xl xl:text-4xl rotate-180 dark:text-aitmes-purple"
        @click="openRow = !openRow">
        &#x2303;
        </button>
      </div>
    </div>
    <div v-show="openRow">
      <ul>
        <li v-for="row in document.rows"
          :key="row.id"
          class="grid grid-flow-col grid-cols-9 gap-x-2 auto-cols-auto bg-zinc-200
          border-t border-white dark:border-slate-700 py-1 text-sm lg:text-base items-center dark:bg-slate-500"
          >
          <div class="col-span-2">
            {{ row.part_number }}
          </div>
          <div class="col-span-2">
            {{ row.config_code }}
          </div>
          <div class="col-span-2">
            {{ row.serial }}
          </div>
          <div class="col-span-2">
            {{ row.qty }}
          </div>
          <div class="col-span-1">
            <button @click="openUpdateModal(row)" class="rounded p-1.5 m-1 bg-aitmes-blue dark:bg-aitmes-purple dark:bg-opacity-75 bg-opacity-75 hover:bg-opacity-100">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 23.389 26.428">
                <g id="Group_99" data-name="Group 99" transform="translate(19.285 -0.001) rotate(40)">
                  <g id="Union_12" data-name="Union 12" transform="translate(-0.002 4.82)" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M.018,19.736H0V19.7l0,0H0V0H5.36V19.713h0l0,.01v.013H5.354L2.681,25.18Z" stroke="none"/>
                    <path d="M 2.684532642364502 20.63460159301758 L 3.360268831253052 19.25786018371582 L 3.360268831253052 2.000004053115845 L 2.001998901367188 2.000004053115845 L 2.001998901367188 19.23925399780273 L 2.684532642364502 20.63460159301758 M 2.681468963623047 25.18000411987305 L 0.01833889074623585 19.73559379577637 L 0.001998891122639179 19.73559379577637 L 0.001998891122639179 3.967285010730848e-06 L 5.360269069671631 3.967285010730848e-06 L 5.360269069671631 19.71271324157715 L 5.364938735961914 19.71272468566895 L 5.360269069671631 19.72223472595215 L 5.360269069671631 19.73559379577637 L 5.353708744049072 19.73559379577637 L 2.681468963623047 25.18000411987305 Z" stroke="none" fill="#fff"/>
                  </g>
                  <g id="Rectangle_80" data-name="Rectangle 80" transform="translate(0.001)" fill="#fff" stroke="#fff" stroke-width="1">
                    <path d="M1,0H4.356a1,1,0,0,1,1,1V3.856a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1A1,1,0,0,1,1,0Z" stroke="none"/>
                    <path d="M1,.5H4.356a.5.5,0,0,1,.5.5V3.356a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none"/>
                  </g>
                </g>
              </svg>
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions } from 'vuex';
import { useToast } from 'vue-toastification';
import UpdateProductionDocumentRow from '@/components/production-documents/UpdateProductionDocumentRow.vue';
import CustomModal from '../base/CustomModal.vue';
import SubmitButton from '../base/SubmitButton.vue';

export default {
  components: {
    CustomModal,
    UpdateProductionDocumentRow,
    SubmitButton,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  computed: {
    formattedDocumentDate() {
      return dayjs(this.document.created_at).format('MM/DD/YYYY');
    },

  },
  data() {
    return {
      pantheraDocNo: '',
      openRow: false,
      activeRow: {},
    };
  },
  methods: {
    ...mapActions('productionDocuments', ['putProductionDocument']),
    addPantheraDocNo() {
      this.putProductionDocument({ id: this.document.id, data: { panthera_document_id: this.pantheraDocNo, transfer_status: 'transferred' } });
      this.$refs.addPantheraNo.closeModal();
      this.toast.success(this.$t('add-panthera-doc-alert'));
    },
    openUpdateModal(row) {
      this.activeRow = row;
      this.$refs.updateRow.openModal();
    },
    onProductionDocumentRowHasBeenUpdated() {
      this.$refs.updateRow.closeModal();
      this.toast.success(this.$t('update-production-doc-alert'));
    },
  },
};
</script>
