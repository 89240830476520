<!-- eslint-disable max-len -->
<template>
  <div class="flex flex-col">
    <div class="flex flex-col items-end">
      <div class="w-10/12 md:w-11/12 flex flex-col justify-center items-center">
        <custom-modal ref="addDepartment">
          <template v-slot:header>
            <h1>{{ $t('add-department.title') }}</h1>
          </template>
          <template v-slot:body>
            <add-department @department-has-been-added="onDepartmentHasBeenAdded"></add-department>
          </template>
        </custom-modal>
        <div class="flex flex-row w-2/3 mx-auto mt-16 relative items-center justify-between md:justify-center">
          <h1 class="text-3xl font-bold text-aitmes-blue dark:text-white w-2/3 md:w-full text-left md:text-center">
            {{ $t('dep-list') }}
          </h1>
          <div class="w-full flex justify-end absolute">
            <button
              class="rounded-md text-white dark:text-slate-700 bg-aitmes-blue dark:bg-white/70 px-2 bg-opacity-75
              hover:bg-opacity-100 text-2xl font-bold"
              @click="$refs.addDepartment.openModal"
            >
            &plus;
            </button>
          </div>
        </div>
        <departments-list></departments-list>
      </div>
    </div>
  </div>
</template>

<script>
import CustomModal from '@/components/base/CustomModal.vue';
import DepartmentsList from '@/components/departments/DepartmentsList.vue';
import AddDepartment from '@/components/departments/AddDepartment.vue';
import { useToast } from 'vue-toastification';

export default {
  setup() {
    const toast = useToast();

    return { toast };
  },
  components: {
    CustomModal,
    DepartmentsList,
    AddDepartment,
  },
  methods: {
    onDepartmentHasBeenAdded() {
      this.$refs.addDepartment.closeModal();
      this.toast.success(this.$t('add-dep-alert'));
    },
  },
};
</script>
