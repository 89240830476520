<!--eslint-disable max-len-->
<template>
  <div class="flex w-full justify-between text-gray-600 bg-white items-center px-4">
    <custom-modal ref="editConfiguration">
      <template v-slot:header>
        <h1>{{ $t('update-config.title') }} ({{ itemConfiguration.item_id }})</h1>
      </template>
      <template v-slot:body>
        <edit-configuration
          :itemConfiguration="itemConfiguration"
          @config-has-been-updated="onConfigurationHasBeenUpdated">
        </edit-configuration>
      </template>
    </custom-modal>
    <tr class="w-full grid grid-cols-7 border-b border-aitmes-blue/50 py-3">
      <td class="col-span-2 px-3">{{itemConfiguration.item_id}}</td>
      <td class="col-span-2 px-3">{{itemConfiguration.config_cod}}</td>
      <td class="col-span-2 break-words px-3">{{itemConfiguration.config_description}}</td>
      <div class="col-span-1 my-auto flex justify-end px-2">
        <button class="rounded p-1.5 bg-aitmes-purple bg-opacity-75 hover:bg-opacity-100
        text-white"
          @click="$refs.editConfiguration.openModal"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23.389 26.428">
            <g id="Group_99" data-name="Group 99" transform="translate(19.285 -0.001) rotate(40)">
              <g id="Union_12" data-name="Union 12" transform="translate(-0.002 4.82)" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path d="M.018,19.736H0V19.7l0,0H0V0H5.36V19.713h0l0,.01v.013H5.354L2.681,25.18Z" stroke="none"/>
                <path d="M 2.684532642364502 20.63460159301758 L 3.360268831253052 19.25786018371582 L 3.360268831253052 2.000004053115845 L 2.001998901367188 2.000004053115845 L 2.001998901367188 19.23925399780273 L 2.684532642364502 20.63460159301758 M 2.681468963623047 25.18000411987305 L 0.01833889074623585 19.73559379577637 L 0.001998891122639179 19.73559379577637 L 0.001998891122639179 3.967285010730848e-06 L 5.360269069671631 3.967285010730848e-06 L 5.360269069671631 19.71271324157715 L 5.364938735961914 19.71272468566895 L 5.360269069671631 19.72223472595215 L 5.360269069671631 19.73559379577637 L 5.353708744049072 19.73559379577637 L 2.681468963623047 25.18000411987305 Z" stroke="none" fill="#fff"/>
              </g>
              <g id="Rectangle_80" data-name="Rectangle 80" transform="translate(0.001)" fill="#fff" stroke="#fff" stroke-width="1">
                <path d="M1,0H4.356a1,1,0,0,1,1,1V3.856a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1A1,1,0,0,1,1,0Z" stroke="none"/>
                <path d="M1,.5H4.356a.5.5,0,0,1,.5.5V3.356a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none"/>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </tr>
  </div>
</template>

<script>
import EditConfiguration from '@/components/item-configurations/EditItemConfiguration.vue';
import CustomModal from '@/components/base/CustomModal.vue';
import { useToast } from 'vue-toastification';

export default {
  setup() {
    const toast = useToast();

    return { toast };
  },
  components: {
    EditConfiguration,
    CustomModal,
  },
  props: {
    itemConfiguration: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onConfigurationHasBeenUpdated() {
      this.$refs.editConfiguration.closeModal();
      this.toast.success(this.$t('update-con-alert'));
    },
  },
};
</script>
