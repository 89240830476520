<!-- eslint-disable max-len -->
<template>
  <div class="flex flex-col">
    <div class="flex flex-col items-end">
      <div class="w-10/12 md:w-11/12 flex flex-col justify-center items-center">
        <custom-modal ref="addUser">
          <template v-slot:header>
            <h1>{{ $t('add-new-user') }}</h1>
          </template>
          <template v-slot:body>
            <add-user @user-has-been-added="onUserHasBeenAdded"></add-user>
          </template>
        </custom-modal>
        <div class="flex flex-col xl:flex-row w-4/5 md:w-1/2
          mx-auto mt-16 mb-8 relative items-center justify-center">
          <h1 class="text-3xl font-bold text-aitmes-blue dark:text-white w-full text-left lg:text-center">
          {{ $t('users-list') }}
          </h1>
          <div class="w-full md:w-5/6 flex justify-end absolute" v-if="$checkPermission('create-user')">
            <button
              class="rounded-md text-white dark:text-slate-700 bg-aitmes-blue dark:bg-white/70 px-2 bg-opacity-75
              hover:bg-opacity-100 text-2xl font-bold"
              @click="$refs.addUser.openModal"
            >
            &plus;
            </button>
          </div>
        </div>
        <users-list v-if="$checkPermission('view-users')"></users-list>
      </div>
    </div>
  </div>
</template>

<script>
import UsersList from '@/components/users/UsersList.vue';
import AddUser from '@/components/users/AddUser.vue';
import CustomModal from '@/components/base/CustomModal.vue';
import { useToast } from 'vue-toastification';

export default {
  setup() {
    const toast = useToast();

    return { toast };
  },
  components: {
    CustomModal,
    UsersList,
    AddUser,
  },
  methods: {
    onUserHasBeenAdded() {
      this.$refs.addUser.closeModal();
      this.toast.success(this.$t('add-user-alert'));
    },
  },
};

</script>
