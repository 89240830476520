import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import DashboardView from '../views/DashboardView.vue';
import CompaniesView from '../views/CompaniesView.vue';
import DepartmentsView from '../views/DepartmentsView.vue';
import ProductionDocumentsView from '../views/ProductionDocumentsView.vue';
import DataCollectionView from '../views/DataCollectionView.vue';
import RolesView from '../views/RolesView.vue';
import UsersView from '../views/UsersView.vue';
import CartsView from '../views/CartsView.vue';
import CartView from '../views/CartView.vue';
import CartSelect from '../components/carts/CartSelect.vue';
import CompanySettings from '../views/CompanySettingsView.vue';
import ItemConfigurations from '../views/ItemConfigurationsView.vue';
import ChangeLogView from '../views/ChangeLogView.vue';
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: { requiresAuth: true },
  },
  {
    path: '/companies',
    name: 'companies',
    component: CompaniesView,
    meta: { requiresAuth: true },
  },
  {
    path: '/departments',
    name: 'departments',
    component: DepartmentsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/data-collection',
    name: 'data-collection',
    component: DataCollectionView,
    meta: { requiresAuth: true },
  },
  {
    path: '/production-documents',
    name: 'production-documents',
    component: ProductionDocumentsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/roles',
    name: 'roles',
    component: RolesView,
    meta: { requiresAuth: true },
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView,
    meta: { requiresAuth: true },
  },
  {
    path: '/carts',
    name: 'carts',
    component: CartsView,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'cartSelect',
        component: CartSelect,
        meta: { requiresAuth: true },
      },
      {
        path: ':id',
        name: 'cart',
        props: true,
        component: CartView,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/company-settings',
    name: 'CompanySettings',
    component: CompanySettings,
  },
  {
    path: '/item-configurations',
    name: 'itemConfigurations',
    component: ItemConfigurations,
    meta: { requiresAuth: true },
  },
  {
    path: '/changelog',
    name: 'change-log',
    component: ChangeLogView,
    meta: { requiresAuth: true },
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  if (to.meta.requiresAuth) {
    to.matched.some(async (record) => {
      // for route different from login/home page check if the user is logged in
      if (!record.name || record.name === 'home') return { name: 'home' }; // go to login page

      // check if not already authenticated
      if (!store.getters['auth/authenticated']) {
        // if not loggedIn try to refresh the credentials first
        await store.dispatch('auth/auth');
        // then retry
        if (!store.getters['auth/authenticated']) {
          return { name: 'home' }; // go to login page
        }
      }
      return { name: record.name };
    });
  }
});

export default router;
