import cartsJson from '@/components/carts/carts.json';

export default {
  namespaced: true,

  state: {
    carts: [],
  },

  getters: {
    carts: (state) => state.carts,
    cartRows: (state) => (id) => {
      const [resp] = state.carts.filter((cart) => cart.id === +id);
      return resp.cart_rows;
    },
  },

  mutations: {
    setCarts(state, carts) {
      state.carts = carts;
    },
  },

  actions: {
    getCarts({ commit }) {
      const { data } = cartsJson;

      commit('setCarts', data);
    },
    async getCart({ state }, id) {
      const [resp] = state.carts.filter((cart) => cart.id === +id);
      return resp;
    },
  },
};
