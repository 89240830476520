<template>
<div class="w-5/6">
  <div class="flex bg-aitmes-blue justify-center rounded-t-2xl p-4
    text-white font-black text-md uppercase mt-10">
      <h2>{{log.release}}</h2>
  </div>
  <div class="bg-white items-start rounded-b-2xl p-4 shadow-lg dark:bg-slate-600">
    <ul class="flex flex-col text-left">
      <log-row class="font-semibold col-span-4 rounded text-white"
      v-for="change in changes" :key="change.id" :change="change">
      </log-row>
    </ul>
  </div>
</div>
</template>

<script>
import LogRow from './LogRow.vue';

export default {
  props: ['log'],
  data() {
    return {
      changes: this.log.changes,
    };
  },
  components: {
    LogRow,
  },
};
</script>
