<!-- eslint-disable max-len -->
<template>
    <div class="flex flex-col w-full items-end">
      <div class="w-11/12 flex flex-col justify-center items-center">
        <router-view />
      </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('carts', ['carts']),
  },
  created() {
    this.getCarts();
  },
  methods: {
    ...mapActions('carts', ['getCarts']),
  },
};
</script>
