<template>
  <form @submit.prevent="addUser" class="flex flex-col items-center my-2">
    <div class="grid grid-cols-2 justify-center w-11/12 md:w-3/4 mx-auto">
      <label for="name" class="flex flex-col m-2 text-left">
        {{ $t('name') }}
        <input
          type="text"
          name="name"
          id="name"
          v-model="user.name"
          required
        />
      </label>
      <label for="username" class="flex flex-col m-2 text-left">
        {{ $t('username') }}
        <input
          type="text"
          name="username"
          id="username"
          v-model="user.username"
          required
        />
      </label>
      <label for="email" class="flex flex-col m-2 text-left" >
        {{ $t('e-mail') }}
        <input
          type="email"
          name="email"
          id="username"
          v-model="user.email"
          required
        />
      </label>
      <label for="password" class="flex flex-col m-2 text-left" >
        {{ $t('password') }}
        <input
          type="password"
          name="password"
          id="password"
          v-model="user.password"
          required
        />
      </label>
      <label for="password-confirmation" class="flex flex-col m-2 text-left">
        {{ $t('password-confirmation') }}
        <input
          type="password"
          name="password-confirmation"
          id="password-confirmation"
          v-model="passwordConfirmation"
          required
        />
      </label>
    </div>
    <div class="flex flex-col justify-center items-center my-2 mx-6">
      <p>{{ $t('enable') }}</p>
      <div class="grid sm:grid-cols-2 sm:gap-x-24 w-fit">
        <label
          v-for="company in companies"
          :key="company.id"
          :for="company.slug"
          class="flex flex-row-reverse justify-end my-2 text-left text-sm md:text-base"
        >
          {{ company.name }}
          <input
            type="checkbox"
            :name="company.slug"
            :id="company.slug"
            :value="company.id"
            v-model="checkedCompanies"
          />
        </label>
      </div>
    </div>
    <submit-button>
        {{ $t('add-user') }}
    </submit-button>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SubmitButton from '../base/SubmitButton.vue';

export default {
  components: {
    SubmitButton,
  },
  data() {
    return {
      user: {
        name: '',
        username: '',
        email: '',
        password: '',
      },
      passwordConfirmation: '',
      checkedCompanies: [],
    };
  },
  computed: {
    ...mapGetters('companies', ['companies']),
  },
  created() {
    if (this.companies) {
      this.getCompanies();
    }
  },
  methods: {
    ...mapActions({
      postUser: 'users/postUser',
      getCompanies: 'companies/getCompanies',
      postAttachCompanies: 'users/postAttachCompanies',
    }),
    async addUser() {
      const user = await this.postUser(this.user);
      this.postAttachCompanies({ id: user.id, companiesList: this.checkedCompanies });
      this.$emit('user-has-been-added');
    },
  },
};
</script>
