<template>
  <div class="flex justify-end">
    <create-production-document></create-production-document>
  </div>
</template>

<script>
import CreateProductionDocument from '@/components/production-documents/CreateProductionDocument.vue';

export default {
  components: {
    CreateProductionDocument,
  },
};
</script>
