import axios from 'axios';

export default {
  namespaced: true,

  state: {
    productionDocuments: [],
    newProductionDocument: {
      id: 0,
      department_id: 0,
      rows: [],
    },
  },

  getters: {
    productionDocuments: (state) => state.productionDocuments,
    newProductionDocument: (state) => state.newProductionDocument,
    newProductionDocumentId: (state) => state.newProductionDocument.id,
    newProductionDocumentDepartmentId: (state) => state.newProductionDocument.department_id,
    newProductionDocumentRows: (state) => state.newProductionDocument.rows,
  },

  mutations: {
    setProductionDocuments(state, productionDocuments) {
      state.productionDocuments = productionDocuments;
    },
    setDepartment(state, departmentId) {
      state.newProductionDocument.department_id = departmentId;
    },
    setNewProductionDocumentRows(state, rows) {
      state.newProductionDocument.rows.push(...rows);
    },
    resetNewProductionDocument(state) {
      state.newProductionDocument.id = 0;
      state.newProductionDocument.department_id = 0;
      state.newProductionDocument.rows = [];
    },
    setNewProductionDocumentId(state, id) {
      state.newProductionDocument.id = id;
    },
  },

  actions: {
    async getProductionDocuments({ commit }, { page, perPage }) {
      const { data } = await axios.get(`/api/production-documents/headers?page=${page}&perPage=${perPage}`);

      commit('setProductionDocuments', data);
    },
    setDepartment({ commit }, departmentId) {
      commit('setDepartment', departmentId);
    },
    setNewProductionDocumentRows({ commit }, rows) {
      commit('setNewProductionDocumentRows', rows);
    },
    async postProductionDocument({ dispatch, commit , state, getters }) { //eslint-disable-line
      const { data } = await axios.post('/api/production-documents/headers', { department_id: getters.newProductionDocumentDepartmentId });

      commit('setNewProductionDocumentId', data.id);

      dispatch('postProductionDocumentRows');

      commit('resetNewProductionDocument');
    },
    async putProductionDocument({ dispatch }, { id, data }) {
      await axios.put(`/api/production-documents/headers/${id}`, data);

      dispatch('getProductionDocuments');
    },
    async postProductionDocumentRows({ dispatch, getters }) { //eslint-disable-line
      await getters.newProductionDocumentRows.forEach((row) => {
        axios.post(`/api/production-documents/${getters.newProductionDocumentId}/rows`, {
          qty: row.qty,
          part_number: row.part_number,
          config_code: row.config_code,
          serial: row.serial,
          action_serial_no: row.action_serial_no,
        });
      });
    },
    async putProductionDocumentRows({ dispatch }, { id, data }) {
      await axios.put(`/api/production-documents/rows/${id}`, data);

      dispatch('getProductionDocuments');
    },
  },
};
