import axios from 'axios';

export default {

  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    activeCompany: null,
  },

  getters: {
    authenticated(state) {
      return state.authenticated;
    },

    isSuperAdmin(state) {
      return state.user.is_super_admin;
    },

    user(state) {
      return state.user;
    },

    activeCompany(state) {
      return state.activeCompany;
    },

    userCompanies(state) {
      return state.user.companies.map(({ slug }) => slug);
    },
    userPermissions(state) {
      return state.user.get_permissions_via_company_roles.map(({ name }) => name);
    },
  },

  mutations: {
    setAuthenticated(state, value) {
      state.authenticated = value;
    },

    setUser(state, value) {
      state.user = value;
    },

    setActiveCompany(state, company) {
      state.activeCompany = company;
    },
  },

  actions: {
    async register({ dispatch }, credentials) {
      await axios.post('/register', credentials);
      return dispatch('me');
    },

    async signIn({ dispatch, commit }, credentials) {
      await axios.get('/sanctum/csrf-cookie');
      await axios.post('/login', credentials);

      commit('setActiveCompany', credentials.company);

      return dispatch('me');
    },

    async signOut({ dispatch }) {
      await axios.post('/logout');

      dispatch('clearCredentials');
    },

    clearCredentials({ commit }) {
      commit('setAuthenticated', false);
      commit('setUser', null);
    },

    me({ commit, dispatch }) {
      return axios.get('/api/user')
        .then(({ data }) => {
          commit('setAuthenticated', true);
          commit('setUser', data);
        }).catch(() => {
          dispatch('clearCredentials');
        });
    },

    auth({ commit }) {
      return axios.get('/api/user')
        .then(({ data }) => {
          commit('setUser', null);
          commit('setAuthenticated', true);
          commit('setUser', data);
        }).catch(() => {
          commit('setAuthenticated', false);
          commit('setUser', null);
        });
    },
  },
};
