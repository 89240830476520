<template>
  <form @submit.prevent="updateCompanySetting" class="flex flex-col">
    <div class="grid grid-cols-2 justify-start my-4 mx-auto w-11/12 md:w-3/4">
      <label for="setting" class="flex flex-col m-2 text-left">
        {{ $t('company-setting') }}
        <input
          type="text"
          name="setting"
          id="setting"
          :value="companySetting.setting"
          disabled
        />
      </label>
      <label for="defaultValue" class="flex flex-col m-2 text-left">
        {{ $t('company-setting-value') }}
        <input
          type="text"
          name="defaultValue"
          id="defaultValue"
          v-model="value"
        />
      </label>
    </div>
    <submit-button>
      {{ $t('update-setting.btn') }}
    </submit-button>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import SubmitButton from '../base/SubmitButton.vue';

export default {
  components: {
    SubmitButton,
  },
  props: {
    companySetting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: this.companySetting.value,
    };
  },
  methods: {
    ...mapActions('companySettings', ['putCompanySetting']),
    updateCompanySetting() {
      this.putCompanySetting({ id: this.companySetting.id, data: { value: this.value } });
      this.$emit('company-setting-has-been-updated');
    },
  },
};
</script>
