<!-- eslint-disable max-len -->
<template>
  <div class="mx-auto flex flex-col items-center w-11/12 lg:w-5/6 xl:w-3/4 my-12">
    <div class="w-full flex justify-between">
      <p class="w-1/3 text-left font-bold text-2xl lg:text-3xl mb-2 text-aitmes-blue dark:text-white">Production Documents' List</p>
      <div class="w-1/3 flex justify-end">
        <div class="w-1/4 flex justify-end items-start text-gray-600 dark:text-white absolute z-50">
          <a
          class="w-full flex justify-end space-x-3 items-center"
          href="/excel-exporters/production-document-rows"
          v-show="$checkPermission('views-production-documents')"
          >
            <p>{{ $t('excel-export') }}</p>
            <svg class="w-1/6 h-full" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85" height="85" viewBox="0 0 85 85">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectangle_2" data-name="Rectangle 2" width="15" height="16.361" fill="#107c41" stroke="#107c41" stroke-width="0.5"/>
                </clipPath>
              </defs>
              <g id="Group_3" data-name="Group 3" transform="translate(-74 -94)">
                <path id="Subtraction_2" data-name="Subtraction 2" d="M80,85H5a5.006,5.006,0,0,1-5-5V5A5.006,5.006,0,0,1,5,0H80a5.006,5.006,0,0,1,5,5V80A5.005,5.005,0,0,1,80,85ZM29.5,12a2,2,0,0,0-2,2V70.753a2,2,0,0,0,2,2H73.363a2,2,0,0,0,2-2V14a2,2,0,0,0-2-2ZM72.363,69.753H53.5V58.025H72.363V69.752Zm-21.863,0h-20V58.025h20V69.752ZM72.363,55.024H53.5V43.46H72.363V55.023Zm-21.863,0h-20V43.46h20V55.023ZM72.363,40.46H53.5v-12.4H72.363v12.4Zm-21.863,0h-20v-12.4h20v12.4Zm21.863-15.4H53.5V15H72.363V25.063Zm-21.863,0h-20V15h20V25.063Z" transform="translate(74 94)" fill="#107c41"/>
                <rect id="Rectangle_3" data-name="Rectangle 3" width="35" height="35" rx="3" transform="translate(84 118.377)" fill="#fff"/>
                <g id="Group_2" data-name="Group 2" transform="translate(94 127.377)">
                  <g id="Group_1" data-name="Group 1" clip-path="url(#clip-path)">
                    <path id="Path_2" data-name="Path 2" d="M0,16.361,6.328,7.835.748,0H3.326L6.295,4.2A23.885,23.885,0,0,1,7.611,6.205,21.466,21.466,0,0,1,8.906,4.341L12.2,0h2.355L8.806,7.712,15,16.361H12.321L8.2,10.524q-.346-.5-.714-1.093-.547.893-.781,1.227L2.6,16.361Z" fill="#107c41" stroke="#107c41" stroke-width="0.5"/>
                  </g>
                </g>
              </g>
            </svg>
          </a>
          <p class="text-right w-2/3 py-1">Items per page:</p>
          <Listbox v-model="perPage">
            <div class="flex flex-col ml-3 w-1/3">
              <ListboxButton class="bg-[url('../assets/images/dropdown-icon.png')] bg-[length:15px] bg-no-repeat
              bg-[center_right_0.5rem] border rounded px-2 py-1 w-full text-left bg-white dark:bg-slate-600
              text-gray-600 dark:text-white">
                {{ perPage }}
              </ListboxButton>
              <ListboxOptions class="text-left text-xs lg:text-base rounded-md
              border border-zinc-200 overflow-hidden w-full bg-white dark:bg-slate-700 mt-1">
                <ListboxOption class="option" value="5">5</ListboxOption>
                <ListboxOption class="option" value="10">10</ListboxOption>
                <ListboxOption class="option" value="15">15</ListboxOption>
                <ListboxOption class="option" value="25">25</ListboxOption>
                <ListboxOption class="option" value="50">50</ListboxOption>
              </ListboxOptions>
            </div>
          </Listbox>
        </div>
      </div>
    </div>
    <production-document
      v-for="document in productionDocuments.data"
      :key="document.id"
      :document="document"
      class="shadow-lg  text-gray-600 m-2 overflow-hidden rounded-md
      border-t-4 border-t-aitmes-purple w-full bg-white dark:bg-slate-600 dark:text-white"
    />
    <router-link :to="{name:'production-documents', query: {'page': page, 'perPage': perPage}}"><!--eslint-disable-line-->
      <paginate class="flex mt-2 dark:text-white"
        :pageCount=productionDocuments.last_page
        :pageRange="3"
        :marginPages="3"
        :click-handler="clickCallback"
        :prevText="'&laquo'"
        :nextText="'&raquo'"
        :containerClass="'pagination'"
        :pageClass="'page-item'"
      >
      </paginate>
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProductionDocument from '@/components/production-documents/ProductionDocument.vue';
import Paginate from 'vuejs-paginate-next';
import { watchEffect } from 'vue';
import {
  Listbox, ListboxButton, ListboxOption, ListboxOptions,
} from '@headlessui/vue';

export default {
  data() {
    return {
      page: 1,
      perPage: 5,
    };
  },
  components: {
    ProductionDocument,
    Paginate,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
  },
  computed: {
    ...mapGetters('productionDocuments', ['productionDocuments']),
  },
  methods: {
    ...mapActions('productionDocuments', ['getProductionDocuments']),
    clickCallback(pageNum) {
      this.page = pageNum;
    },
    navigate(pageTo, perPageTo) {
      this.page = parseInt(pageTo, 10);
      this.perPage = parseInt(perPageTo, 10);
      this.$router.push({ name: 'production-documents', query: { page: this.page, perPage: this.perPage } });
      this.getProductionDocuments({ page: this.page, perPage: this.perPage });
    },
  },
  watch: {
    $route: {
      handler(to) {
        if (to.query.page && to.query.perPage) {
          this.navigate(to.query.page, to.query.perPage);
        }
      },
    },
  },
  created() {
    watchEffect(async () => {
      await fetch(this.perPage);
      this.$router.push({ name: 'production-documents', query: { page: 1, perPage: this.perPage } });
      this.getProductionDocuments({ page: 1, perPage: this.perPage });
    });
  },
};
</script>

<style>
.page-link {
  @apply py-1 px-3
}

.page-link:focus {
  @apply border-t-2 border-aitmes-purple text-aitmes-purple font-black dark:text-white
}

.option {
  @apply hover:bg-aitmes-purple hover:text-white py-2 px-3 font-bold border-b border-zinc-200
}
</style>
