<template>
  <div class="mx-auto space-y-3 xl:space-y-7 my-4">
    <label for="username" class="w-full flex lg:text-lg text-left flex-col font-bold
    text-aitmes-blue dark:text-white">
      {{ $t('username') }}
      <input
        type="text"
        id="username"
        class="lg:px-4 lg:py-4 form-bg dark:border-aitmes-purple"
        v-model="credentials.username"
        @blur="fetchUserCompanies"
      >
    </label>
    <label for="password" class="w-full flex lg:text-lg text-left flex-col font-bold
    text-aitmes-blue dark:text-white">
    {{ $t('password') }}
      <input
        type="password"
        id="password"
        class="lg:px-4 lg:py-4 form-bg dark:border-aitmes-purple"
        v-model="credentials.password"
      >
    </label>
    <label for="companies" v-show="companies.length"
    class="w-full flex lg:text-lg text-left flex-col font-bold text-aitmes-blue dark:text-white">
    {{ $t('company') }}
      <select name="companies" id="companies" v-model="credentials.company"
      class="border rounded-lg p-2 mt-1 font-thin bg-transparent text-sm lg:text-base
      lg:px-4 lg:py-4 form-bg select dark:border-aitmes-purple
      focus:outline-none focus:ring-2 focus:ring-aitmes-purple/80">
        <option
          v-for="company in companies"
          :value="company.slug"
          :key="company.id">
          {{ company.name }}
        </option>
      </select>
    </label>
    <div class="w-full flex mt-2" v-show="weHaveCompanies">
      <button
        class="px-2 py-1 lg:px-4 lg:py-2 rounded-md lg:rounded-lg border-2 border-aitmes-blue
        text-aitmes-blue dark:text-white font-black uppercase hover:ring-2 text-sm lg:text-md
        dark:border-aitmes-purple hover:dark:bg-aitmes-purple hover:dark:ring-0"
        @click="login"
      >
        {{ $t('login') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      credentials: {
        username: '',
        password: '',
        company: '',
      },
      companies: [],
    };
  },
  computed: {
    weHaveCompanies() {
      return this.companies.length > 0;
    },
    username() {
      return this.credentials.username;
    },
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn',
      getUserCompanies: 'users/getUserCompanies',
    }),
    login() {
      this.signIn(this.credentials)
        .then(() => {
          this.$router.push('/dashboard');
        });
    },
    async fetchUserCompanies() {
      this.companies = await this.getUserCompanies(this.credentials.username);
      this.credentials.company = this.companies[0].slug;
    },
  },
};
</script>

<style scoped>
.form-bg {
    background: white;
    -webkit-appearance: none;
    @apply dark:bg-slate-700
}

.select {
   appearance: none;
   background-image: url("@/assets/images/dropdown-icon.png");
      background-repeat: no-repeat;
      background-position: right 1em top 50%, 0 0;
      background-size: 20px;
}
</style>
