<!-- eslint-disable max-len -->
<template>
  <div class="flex flex-col">
    <div class="flex flex-col items-end">
      <div class="w-10/12 sm:w-11/12 flex flex-col justify-center items-center">
        <custom-modal ref="addRole">
          <template v-slot:header>
            <h1>{{ $t('add-new-role') }}</h1>
          </template>
          <template v-slot:body>
            <add-role @role-has-been-added="onRoleHasBeenAdded"></add-role>
          </template>
        </custom-modal>
        <div class="flex flex-col xl:flex-row w-8/12 xl:w-5/12 mx-auto mt-16 relative items-center justify-center mb-8">
          <h1 class="text-3xl font-bold text-aitmes-blue dark:text-white w-full text-left lg:text-center">
            {{ $t('roles-list') }}
          </h1>
          <div class="w-full flex justify-end absolute">
            <button
              class="rounded-md text-white dark:text-slate-700 bg-aitmes-blue dark:bg-white/70 px-2 bg-opacity-75
              hover:bg-opacity-100 text-2xl font-bold"
              @click="$refs.addRole.openModal"
            >
            &plus;
            </button>
          </div>
        </div>
        <roles-list></roles-list>
      </div>
    </div>
  </div>
</template>

<script>

import { useToast } from 'vue-toastification';
import CustomModal from '@/components/base/CustomModal.vue';
import RolesList from '@/components/roles/RolesList.vue';
import AddRole from '@/components/roles/AddRole.vue';

export default {
  setup() {
    const toast = useToast();

    return { toast };
  },
  components: {
    CustomModal,
    RolesList,
    AddRole,
  },
  methods: {
    onRoleHasBeenAdded() {
      this.$refs.addRole.closeModal();
      this.toast.success(this.$t('add-role-alert'));
    },
  },
};
</script>
