<template>
  <form @submit.prevent="addDepartment" class="flex flex-col">
    <div class="grid grid-cols-2 justify-center my-4 w-11/12 md:w-3/4 mx-auto">
      <label for="name" class="flex flex-col m-2 text-left">
        {{ $t('department-name') }}
        <input
            type="text"
            name="name"
            id="name"
            v-model="department.name"
            required
        />
      </label>
      <label for="slug" class="flex flex-col m-2 text-left">
        {{ $t('slug') }}
        <input
            type="text"
            name="slug"
            id="slug"
            v-model="department.slug"
            required
        />
      </label>
      <label for="name" class="flex flex-col m-2 text-left">
        {{ $t('panthera-dep-code') }}
        <input
            type="text"
            name="name"
            id="name"
            v-model="department.panthera_dep_code"
            required
        />
      </label>
    </div>
    <submit-button>
      {{ $t('add-department.btn') }}
    </submit-button>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import SubmitButton from '../base/SubmitButton.vue';

export default {
  components: {
    SubmitButton,
  },
  data() {
    return {
      department: {
        name: '',
        slug: '',
        pathera_dep_code: '',
      },
    };
  },
  methods: {
    ...mapActions('departments', ['postDepartment']),
    async addDepartment() {
      await this.postDepartment(this.department);
      this.$emit('department-has-been-added');
    },
  },
};
</script>
