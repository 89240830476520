<!-- eslint-disable max-len -->
<template>
  <button
    @click="logout"
  >
    <svg class="py-2 pl-1 w-10 h-10 rounded-md md:w-12 md:h-12 xl:w-14 xl:h-14 md:rounded-xl bg-white bg-opacity-40 hover:bg-opacity-80 stroke-white active:bg-opacity-100 hover:stroke-aitmes-purple" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="41.049" height="40" viewBox="0 0 41.049 40">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_117" data-name="Rectangle 117" width="41.049" height="40" fill="none" stroke-width="3"/>
        </clipPath>
      </defs>
      <g id="Group_82" data-name="Group 82" transform="translate(3 3)">
        <g id="Group_81" data-name="Group 81" transform="translate(-3 -3)" clip-path="url(#clip-path)">
          <path id="Path_25" data-name="Path 25" d="M26.039,8.989V4.076A3.076,3.076,0,0,0,22.963,1H4.076A3.076,3.076,0,0,0,1,4.076V32.637a3.076,3.076,0,0,0,3.076,3.076H22.963a3.076,3.076,0,0,0,3.076-3.076V27.724" transform="translate(1.644 1.644)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4"/>
          <path id="Path_26" data-name="Path 26" d="M45.368,30.545l6.2-6.745-6.2-6.745" transform="translate(-13.401 -3.8)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4"/>
          <line id="Line_6" data-name="Line 6" x2="22.591" transform="translate(15.578 20)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4"/>
        </g>
      </g>
    </svg>
  </button>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
    }),
    logout() {
      this.signOut();
      this.$router.push('/');
    },
  },
};
</script>
