<template>
    <div>
        Company Settings
        <company-settings></company-settings>
    </div>
</template>

<script>
import CompanySettings from '@/components/company-settings/CompanySettingsList.vue';

export default {
  components: {
    CompanySettings,
  },
};
</script>
