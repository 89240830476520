<template>
  <label for="qty" class="flex flex-col text-left text-sm lg:text-base dark:text-white">
    {{ $t('quantity') }}
    <input
      type="text"
      class="rounded"
      name="qty"
      :value="qty"
      @input="$emit('update:qty', $event.target.value)"
      required
    />
  </label>
  <label for="partNumber" class="col-span-1 text-left text-sm lg:text-base dark:text-white">
    {{ $t('part-number') }}
    <input
      type="text"
      class="rounded"
      name="partNumber"
      :value="itemCode"
      @input="onItemCodeInput"
      required
      @click="closeList"
      autocomplete="off"
    />
    <ul class="text-left text-xs lg:text-base rounded-md
          mt-1 border border-zinc-200 dark:border-slate-700 overflow-hidden grid auto-cols-max w-fit
          z-40 absolute text-gray-600">
      <li
        v-for="item in items" :key="item.id"
        class="py-2 px-3 font-bold border-b last:border-none border-zinc-200 dark:border-b-2
        dark:border-slate-700 bg-white dark:bg-slate-600 dark:text-white
        hover:dark:bg-aitmes-purple hover:bg-aitmes-purple hover:text-white"
        @click="$emit('update:itemCode', item.item_code); items = [];"
        @keypress.enter="$emit('update:itemCode', item.item_code); items = [];"
      >
        {{ item.item_code }} <br><span class="font-light">{{ item.description }}</span>
      </li>
    </ul>
  </label>
  <label for="configuration" class="flex flex-col text-left text-sm lg:text-base dark:text-white">
    {{ $t('configuration') }}
    <input
      type="text"
      class="rounded"
      name="configuration"
      :value="configCode"
      @input="$emit('update:configCode', $event.target.value)"
    />
  </label>
  <label for="serial" class="flex flex-col text-left text-sm lg:text-base dark:text-white">
    {{ $t('serial') }}
    <input
      type="text"
      class="rounded"
      name="serial"
      :value="serial"
      @input="$emit('update:serial', $event.target.value)"
    />
  </label>
  <label for="action_serial" class="flex flex-col text-left text-sm lg:text-base dark:text-white">
    {{ $t('action-serial') }}
    <input
      type="text"
      class="rounded"
      name="action_serial"
      :value="actionSerial"
      @input="$emit('update:actionSerial', $event.target.value)"
    />
  </label>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    qty: {
      type: Number,
      default: 1,
    },
    itemCode: {
      type: String,
      default: '',
    },
    configCode: {
      type: String,
      default: '',
    },
    serial: {
      type: String,
      default: '',
    },
    actionSerial: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    onItemCodeInput(e) {
      axios.get(`/api/full-text/items?search=${e.target.value}`)
        .then(({ data }) => {
          this.items = data.slice(0, 10);
        });
      this.$emit('update:itemCode', e.target.value);
    },
    closeList() {
      if (this.onItemCodeInput) {
        this.items = [];
      }
    },
  },
  created() {
    window.addEventListener('click', this.closeList);
  },
  unmounted() {
    window.removeEventListener('click', this.closeList);
  },
};
</script>
