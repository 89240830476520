<!-- eslint-disable max-len -->
<template>
  <div class="w-5/6 lg:w-4/5 h-screen mt-12 mx-auto">
    <p class="text-xl lg:text-3xl uppercase font-bold text-aitmes-blue dark:text-white">
      {{ $t('scan-enter-item') }}
    </p>
    <form @submit.prevent="saveProductionDocument">
      <ul
        v-for="row in rows"
        :key="row"
        class="flex mb-4"
      >
        <li
          class="grid grid-rows-6 grid-cols-1 md:grid-cols-6 md:grid-rows-1 gap-y-4 md:gap-x-3 lg:gap-x-6"
        >
          <div
            class="flex justify-around h-16 items-end"
          >
            <button
              class="rounded px-2 py-1 lg:px-4 lg:py-2 bg-lime-600 text-white text-xl font-bold active:bg-lime-800"
              @click="addRow"
            >
              &#43;
            </button>
            <button
              class="rounded px-2 py-1 lg:px-4 lg:py-2 bg-red-700 text-white text-xl font-bold active:bg-red-900"
              @click="removeRow(row)"
              v-show="rows.length > 1"
            >
              &#8722;
            </button>
          </div>
          <data-collection-row
            v-model:qty="row.qty"
            v-model:itemCode="row.part_number"
            v-model:configCode="row.config_code"
            v-model:serial="row.serial"
            v-model:actionSerial="row.action_serial_no"
          />
        </li>
      </ul>
      <div class="w-full flex justify-center">
        <button
        class="rounded-lg w-fit mt-5 mx-auto border-2 border-aitmes-blue text-aitmes-blue
        dark:border-aitmes-purple dark:text-white px-6 py-2 hover:dark:bg-aitmes-purple
        hover:dark:text-slate-700 text-xl font-bold hover:ring-4 ring-aitmes-blue ring-opacity-25
        hover:dark:ring-0"
        >
          {{ $t('save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DataCollectionRow from './DataCollectionRow.vue';

export default {
  emits: ['production-document-has-been-saved'],
  components: { DataCollectionRow },
  data() {
    return {
      rows: [
        {
          qty: 1,
          part_number: '',
          config_code: '',
          serial: '',
          action_serial_no: '',
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      setNewProductionDocumentRows: 'productionDocuments/setNewProductionDocumentRows',
      postProductionDocument: 'productionDocuments/postProductionDocument',
    }),
    addRow() {
      const newRow = {
        qty: 1,
        part_number: '',
        config_code: '',
        serial: '',
        action_serial_no: '',
      };
      this.rows.push(newRow);
    },
    removeRow(row) {
      this.rows.splice(this.rows.indexOf(row), 1);
    },
    saveProductionDocument() {
      this.setNewProductionDocumentRows(this.rows);
      this.postProductionDocument();
      this.$emit('production-document-has-been-saved');
    },
  },
};
</script>
