<!-- eslint-disable max-len -->
<template>
  <div class="flex justify-center">
    <div class="w-11/12 flex flex-col justify-center items-center">
      <div class="w-full flex flex-col items-center">
        <svg class="my-12 dark:hidden" id="Group_87" data-name="Group 87" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="206.056" height="69.645" viewBox="0 0 206.056 69.645">
          <defs>
            <clipPath id="clip-path">
              <rect id="Rectangle_120" data-name="Rectangle 120" width="206.056" height="69.645" transform="translate(0 0)" fill="none"/>
            </clipPath>
          </defs>
          <g id="Group_86" data-name="Group 86" clip-path="url(#clip-path)">
            <path id="Path_34" data-name="Path 34" d="M81.5,65.437h8.137a2.138,2.138,0,0,1,1.457.418,1.7,1.7,0,0,1,.47,1.331,1.867,1.867,0,0,1-.443,1.432,2.527,2.527,0,0,1-1.636.393H69.837a2.213,2.213,0,0,1-1.522-.456,1.752,1.752,0,0,1-.506-1.369,1.594,1.594,0,0,1,.531-1.318,2.508,2.508,0,0,1,1.6-.431H77.67V49.163H72.708a2.389,2.389,0,0,1-1.59-.466,1.955,1.955,0,0,1-.04-2.668,2.272,2.272,0,0,1,1.522-.44h7.426a1.84,1.84,0,0,1,1.078.278.883.883,0,0,1,.393.761ZM76.808,38.288a7.324,7.324,0,0,1,.493-3.447,2.469,2.469,0,0,1,2.118-.71,2.709,2.709,0,0,1,2.078.6A4.974,4.974,0,0,1,82,37.553a4.741,4.741,0,0,1-.47,2.7,2.968,2.968,0,0,1-2.116.519,3.209,3.209,0,0,1-2.118-.481,2.889,2.889,0,0,1-.493-2" fill="#306691"/>
            <path id="Path_35" data-name="Path 35" d="M112.023,47.008a3.028,3.028,0,0,1,1.812.431,1.6,1.6,0,0,1,.571,1.369,1.635,1.635,0,0,1-.533,1.331,2.452,2.452,0,0,1-1.6.443H102.01v8.467q0,4.156,1.077,5.475a4.522,4.522,0,0,0,3.689,1.318A14.8,14.8,0,0,0,112.3,64.5a16.246,16.246,0,0,1,3.98-1.344,1.619,1.619,0,0,1,1.2.507,1.714,1.714,0,0,1,.494,1.243,2,2,0,0,1-.621,1.457,6.292,6.292,0,0,1-2.117,1.2,31.033,31.033,0,0,1-4.474,1.445,16.775,16.775,0,0,1-3.739.456,11.638,11.638,0,0,1-4.981-.95,6.349,6.349,0,0,1-3-2.827,6.471,6.471,0,0,1-.722-2.1,23.528,23.528,0,0,1-.216-3.751V50.582H93.8a2.331,2.331,0,0,1-1.546-.418,1.709,1.709,0,0,1-.482-1.356,1.5,1.5,0,0,1,.659-1.42,6.631,6.631,0,0,1,2.814-.38h2.864v-6.54a2.353,2.353,0,0,1,.469-1.61,1.872,1.872,0,0,1,1.458-.519,1.737,1.737,0,0,1,1.546.633,6.069,6.069,0,0,1,.431,2.89v5.146Z" fill="#306691"/>
            <path id="Path_36" data-name="Path 36" d="M145.661,54.892a12.048,12.048,0,0,0-.644-4.829,2.278,2.278,0,0,0-2.21-1.255,3.414,3.414,0,0,0-2.968,1.623,7.87,7.87,0,0,0-1.1,4.461V65.437h.452a3.874,3.874,0,0,1,2.049.368,1.592,1.592,0,0,1,.515,1.381,1.923,1.923,0,0,1-.431,1.369,1.732,1.732,0,0,1-1.318.456h-3.219a1.79,1.79,0,0,1-1.483-.506,3.974,3.974,0,0,1-.393-2.181V52.915a4.752,4.752,0,0,0-.862-3,2.829,2.829,0,0,0-2.357-1.1,3.545,3.545,0,0,0-2.092.672,5.453,5.453,0,0,0-1.635,1.99V65.437h.931a3.786,3.786,0,0,1,2.039.38,1.58,1.58,0,0,1,.528,1.369,1.9,1.9,0,0,1-.43,1.433,2.5,2.5,0,0,1-1.643.392h-6.727a2.049,2.049,0,0,1-1.454-.443,1.82,1.82,0,0,1-.468-1.382,1.5,1.5,0,0,1,.591-1.381,4.854,4.854,0,0,1,2.3-.368h.5V49.163h-.453a4.943,4.943,0,0,1-2.4-.4,1.531,1.531,0,0,1-.642-1.409,1.657,1.657,0,0,1,.507-1.322,2.269,2.269,0,0,1,1.521-.44h3.879a1.514,1.514,0,0,1,1.1.29,2.087,2.087,0,0,1,.317,1.22A6.935,6.935,0,0,1,130.2,45.46a6.556,6.556,0,0,1,2.692-.53,5.186,5.186,0,0,1,2.933.769,4.517,4.517,0,0,1,1.715,2.331A7.046,7.046,0,0,1,140,45.686a6.606,6.606,0,0,1,3.2-.756,5.458,5.458,0,0,1,4.782,2.129q1.508,2.13,1.509,6.9V65.437h.4a3.907,3.907,0,0,1,2.086.38,1.58,1.58,0,0,1,.528,1.369,1.859,1.859,0,0,1-.456,1.369,1.822,1.822,0,0,1-1.344.456h-3.067a1.788,1.788,0,0,1-1.495-.608,3.1,3.1,0,0,1-.482-1.926Z" fill="#306691"/>
            <path id="Path_37" data-name="Path 37" d="M158.842,58.416a7.743,7.743,0,0,0,2.472,5.659,9.058,9.058,0,0,0,6.2,2,15.794,15.794,0,0,0,6.464-1.584,17.51,17.51,0,0,1,4.233-1.584,1.767,1.767,0,0,1,1.267.481,1.577,1.577,0,0,1,.507,1.192,1.983,1.983,0,0,1-.532,1.341,5.527,5.527,0,0,1-1.7,1.187,25.852,25.852,0,0,1-5.235,1.887,22.707,22.707,0,0,1-5.361.654,12.8,12.8,0,0,1-9.253-3.333,11.681,11.681,0,0,1-3.473-8.834,11.913,11.913,0,0,1,3.676-9.075,13.386,13.386,0,0,1,9.582-3.473,12.593,12.593,0,0,1,8.872,3.245,10.293,10.293,0,0,1,3.524,7.858,2.376,2.376,0,0,1-.6,1.9q-.6.482-3.232.482H158.842Zm.127-3.321h16.5a7.359,7.359,0,0,0-2.522-4.854,7.794,7.794,0,0,0-5.26-1.838,9.034,9.034,0,0,0-5.678,1.774,8.378,8.378,0,0,0-3.042,4.918" fill="#306691"/>
            <path id="Path_38" data-name="Path 38" d="M184.512,67.29V62.877a2.374,2.374,0,0,1,.409-1.562,1.671,1.671,0,0,1,1.326-.471q1.017,0,1.612,1.389a7.2,7.2,0,0,0,.719,1.264,7.09,7.09,0,0,0,3.074,2.144,12.059,12.059,0,0,0,4.487.806,7.435,7.435,0,0,0,4.214-1.066,3.241,3.241,0,0,0,1.587-2.8,2.663,2.663,0,0,0-1.277-2.5,9.28,9.28,0,0,0-4.375-.744h-1.364q-5.057,0-7.71-1.76a5.751,5.751,0,0,1-2.652-5.107,5.843,5.843,0,0,1,2.59-5.082q2.591-1.811,7.276-1.81a14.98,14.98,0,0,1,3.893.533q1.983.532,2.082.533a2.668,2.668,0,0,0,1.041-.483,2.829,2.829,0,0,1,1.512-.484,1.4,1.4,0,0,1,1.252.62,3.4,3.4,0,0,1,.409,1.859v2.9a3.468,3.468,0,0,1-.4,1.9,1.42,1.42,0,0,1-1.265.583q-.742,0-1.834-1.264a16.919,16.919,0,0,0-1.264-1.314,8.2,8.2,0,0,0-2.8-1.748,10.369,10.369,0,0,0-3.495-.533A6.275,6.275,0,0,0,189.8,49.7a3.11,3.11,0,0,0-1.426,2.615,2.291,2.291,0,0,0,1.463,2.231q1.463.67,6.3.769,5.157.124,7.536,1.8a5.887,5.887,0,0,1,2.38,5.169,6.4,6.4,0,0,1-2.628,5.355,11.392,11.392,0,0,1-7.09,2.008,21.463,21.463,0,0,1-3.148-.26q-1.735-.261-4.363-.88a11.852,11.852,0,0,1-1.488.855,2.605,2.605,0,0,1-1.041.285,1.558,1.558,0,0,1-1.326-.607,2.859,2.859,0,0,1-.459-1.748" fill="#306691"/>
            <path id="Path_39" data-name="Path 39" d="M38.4,11.048l-3.914-8.7a3.669,3.669,0,0,0-6.849,0L.409,62.858c-1.272,2.827.579,6.153,3.425,6.153h8.483Z" fill="#a93266"/>
            <path id="Path_40" data-name="Path 40" d="M61.953,62.858,54.548,46.4s-.006-.008-.011-.008h-18.4a1.755,1.755,0,0,1-.122-3.5H52.917a.044.044,0,0,0,.039-.031L40.3,14.745c-.022-.014-.049-.028-.062,0L15.853,68.937a.051.051,0,0,0,.041.074H58.528c2.846,0,4.7-3.326,3.425-6.153" fill="#a93266"/>
          </g>
        </svg>
        <svg class="my-12 hidden dark:flex" id="Group_87" data-name="Group 87" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="206.056" height="69.645" viewBox="0 0 206.056 69.645">
          <defs>
            <clipPath id="clip-path">
              <rect id="Rectangle_120" data-name="Rectangle 120" width="206.056" height="69.645" transform="translate(0 0)" fill="none"/>
            </clipPath>
          </defs>
          <g id="Group_86" data-name="Group 86" clip-path="url(#clip-path)">
            <path id="Path_34" data-name="Path 34" d="M81.5,65.437h8.137a2.138,2.138,0,0,1,1.457.418,1.7,1.7,0,0,1,.47,1.331,1.867,1.867,0,0,1-.443,1.432,2.527,2.527,0,0,1-1.636.393H69.837a2.213,2.213,0,0,1-1.522-.456,1.752,1.752,0,0,1-.506-1.369,1.594,1.594,0,0,1,.531-1.318,2.508,2.508,0,0,1,1.6-.431H77.67V49.163H72.708a2.389,2.389,0,0,1-1.59-.466,1.955,1.955,0,0,1-.04-2.668,2.272,2.272,0,0,1,1.522-.44h7.426a1.84,1.84,0,0,1,1.078.278.883.883,0,0,1,.393.761ZM76.808,38.288a7.324,7.324,0,0,1,.493-3.447,2.469,2.469,0,0,1,2.118-.71,2.709,2.709,0,0,1,2.078.6A4.974,4.974,0,0,1,82,37.553a4.741,4.741,0,0,1-.47,2.7,2.968,2.968,0,0,1-2.116.519,3.209,3.209,0,0,1-2.118-.481,2.889,2.889,0,0,1-.493-2" fill="#fff"/>
            <path id="Path_35" data-name="Path 35" d="M112.023,47.008a3.028,3.028,0,0,1,1.812.431,1.6,1.6,0,0,1,.571,1.369,1.635,1.635,0,0,1-.533,1.331,2.452,2.452,0,0,1-1.6.443H102.01v8.467q0,4.156,1.077,5.475a4.522,4.522,0,0,0,3.689,1.318A14.8,14.8,0,0,0,112.3,64.5a16.246,16.246,0,0,1,3.98-1.344,1.619,1.619,0,0,1,1.2.507,1.714,1.714,0,0,1,.494,1.243,2,2,0,0,1-.621,1.457,6.292,6.292,0,0,1-2.117,1.2,31.033,31.033,0,0,1-4.474,1.445,16.775,16.775,0,0,1-3.739.456,11.638,11.638,0,0,1-4.981-.95,6.349,6.349,0,0,1-3-2.827,6.471,6.471,0,0,1-.722-2.1,23.528,23.528,0,0,1-.216-3.751V50.582H93.8a2.331,2.331,0,0,1-1.546-.418,1.709,1.709,0,0,1-.482-1.356,1.5,1.5,0,0,1,.659-1.42,6.631,6.631,0,0,1,2.814-.38h2.864v-6.54a2.353,2.353,0,0,1,.469-1.61,1.872,1.872,0,0,1,1.458-.519,1.737,1.737,0,0,1,1.546.633,6.069,6.069,0,0,1,.431,2.89v5.146Z" fill="#fff"/>
            <path id="Path_36" data-name="Path 36" d="M145.661,54.892a12.048,12.048,0,0,0-.644-4.829,2.278,2.278,0,0,0-2.21-1.255,3.414,3.414,0,0,0-2.968,1.623,7.87,7.87,0,0,0-1.1,4.461V65.437h.452a3.874,3.874,0,0,1,2.049.368,1.592,1.592,0,0,1,.515,1.381,1.923,1.923,0,0,1-.431,1.369,1.732,1.732,0,0,1-1.318.456h-3.219a1.79,1.79,0,0,1-1.483-.506,3.974,3.974,0,0,1-.393-2.181V52.915a4.752,4.752,0,0,0-.862-3,2.829,2.829,0,0,0-2.357-1.1,3.545,3.545,0,0,0-2.092.672,5.453,5.453,0,0,0-1.635,1.99V65.437h.931a3.786,3.786,0,0,1,2.039.38,1.58,1.58,0,0,1,.528,1.369,1.9,1.9,0,0,1-.43,1.433,2.5,2.5,0,0,1-1.643.392h-6.727a2.049,2.049,0,0,1-1.454-.443,1.82,1.82,0,0,1-.468-1.382,1.5,1.5,0,0,1,.591-1.381,4.854,4.854,0,0,1,2.3-.368h.5V49.163h-.453a4.943,4.943,0,0,1-2.4-.4,1.531,1.531,0,0,1-.642-1.409,1.657,1.657,0,0,1,.507-1.322,2.269,2.269,0,0,1,1.521-.44h3.879a1.514,1.514,0,0,1,1.1.29,2.087,2.087,0,0,1,.317,1.22A6.935,6.935,0,0,1,130.2,45.46a6.556,6.556,0,0,1,2.692-.53,5.186,5.186,0,0,1,2.933.769,4.517,4.517,0,0,1,1.715,2.331A7.046,7.046,0,0,1,140,45.686a6.606,6.606,0,0,1,3.2-.756,5.458,5.458,0,0,1,4.782,2.129q1.508,2.13,1.509,6.9V65.437h.4a3.907,3.907,0,0,1,2.086.38,1.58,1.58,0,0,1,.528,1.369,1.859,1.859,0,0,1-.456,1.369,1.822,1.822,0,0,1-1.344.456h-3.067a1.788,1.788,0,0,1-1.495-.608,3.1,3.1,0,0,1-.482-1.926Z" fill="#fff"/>
            <path id="Path_37" data-name="Path 37" d="M158.842,58.416a7.743,7.743,0,0,0,2.472,5.659,9.058,9.058,0,0,0,6.2,2,15.794,15.794,0,0,0,6.464-1.584,17.51,17.51,0,0,1,4.233-1.584,1.767,1.767,0,0,1,1.267.481,1.577,1.577,0,0,1,.507,1.192,1.983,1.983,0,0,1-.532,1.341,5.527,5.527,0,0,1-1.7,1.187,25.852,25.852,0,0,1-5.235,1.887,22.707,22.707,0,0,1-5.361.654,12.8,12.8,0,0,1-9.253-3.333,11.681,11.681,0,0,1-3.473-8.834,11.913,11.913,0,0,1,3.676-9.075,13.386,13.386,0,0,1,9.582-3.473,12.593,12.593,0,0,1,8.872,3.245,10.293,10.293,0,0,1,3.524,7.858,2.376,2.376,0,0,1-.6,1.9q-.6.482-3.232.482H158.842Zm.127-3.321h16.5a7.359,7.359,0,0,0-2.522-4.854,7.794,7.794,0,0,0-5.26-1.838,9.034,9.034,0,0,0-5.678,1.774,8.378,8.378,0,0,0-3.042,4.918" fill="#fff"/>
            <path id="Path_38" data-name="Path 38" d="M184.512,67.29V62.877a2.374,2.374,0,0,1,.409-1.562,1.671,1.671,0,0,1,1.326-.471q1.017,0,1.612,1.389a7.2,7.2,0,0,0,.719,1.264,7.09,7.09,0,0,0,3.074,2.144,12.059,12.059,0,0,0,4.487.806,7.435,7.435,0,0,0,4.214-1.066,3.241,3.241,0,0,0,1.587-2.8,2.663,2.663,0,0,0-1.277-2.5,9.28,9.28,0,0,0-4.375-.744h-1.364q-5.057,0-7.71-1.76a5.751,5.751,0,0,1-2.652-5.107,5.843,5.843,0,0,1,2.59-5.082q2.591-1.811,7.276-1.81a14.98,14.98,0,0,1,3.893.533q1.983.532,2.082.533a2.668,2.668,0,0,0,1.041-.483,2.829,2.829,0,0,1,1.512-.484,1.4,1.4,0,0,1,1.252.62,3.4,3.4,0,0,1,.409,1.859v2.9a3.468,3.468,0,0,1-.4,1.9,1.42,1.42,0,0,1-1.265.583q-.742,0-1.834-1.264a16.919,16.919,0,0,0-1.264-1.314,8.2,8.2,0,0,0-2.8-1.748,10.369,10.369,0,0,0-3.495-.533A6.275,6.275,0,0,0,189.8,49.7a3.11,3.11,0,0,0-1.426,2.615,2.291,2.291,0,0,0,1.463,2.231q1.463.67,6.3.769,5.157.124,7.536,1.8a5.887,5.887,0,0,1,2.38,5.169,6.4,6.4,0,0,1-2.628,5.355,11.392,11.392,0,0,1-7.09,2.008,21.463,21.463,0,0,1-3.148-.26q-1.735-.261-4.363-.88a11.852,11.852,0,0,1-1.488.855,2.605,2.605,0,0,1-1.041.285,1.558,1.558,0,0,1-1.326-.607,2.859,2.859,0,0,1-.459-1.748" fill="#fff"/>
            <path id="Path_39" data-name="Path 39" d="M38.4,11.048l-3.914-8.7a3.669,3.669,0,0,0-6.849,0L.409,62.858c-1.272,2.827.579,6.153,3.425,6.153h8.483Z" fill="#a93266"/>
            <path id="Path_40" data-name="Path 40" d="M61.953,62.858,54.548,46.4s-.006-.008-.011-.008h-18.4a1.755,1.755,0,0,1-.122-3.5H52.917a.044.044,0,0,0,.039-.031L40.3,14.745c-.022-.014-.049-.028-.062,0L15.853,68.937a.051.051,0,0,0,.041.074H58.528c2.846,0,4.7-3.326,3.425-6.153" fill="#a93266"/>
          </g>
        </svg>
        <div class="w-3/5 flex mx-auto">
          <div class="flex w-full justify-between">
            <dashboard-card>
              <template v-slot:title>
                <p>{{ $t('management') }}</p>
              </template>
              <template v-slot:links>
                <router-link
                  to="/companies"
                  v-show="$checkPermission('create-company')"
                >
                  {{ $t('companies') }}
                </router-link>
                <router-link
                  to="/company-settings"
                  v-show="$checkPermission('view-company-settings')"
                >
                  {{ $t('company-settings') }}
                </router-link>
                <router-link
                  to="/departments"
                  v-show="$checkPermission('create-departments')"
                >
                  {{ $t('departments') }}
                </router-link>
                <router-link
                  to="/roles"
                  v-show="$checkPermission('create-role')"
                >
                  {{ $t('roles') }}
                </router-link>
                <router-link
                  to="/users"
                  v-show="$checkPermission('create-user')"
                  >
                  {{ $t('users') }}
                </router-link>
              </template>
            </dashboard-card>
            <dashboard-card>
              <template v-slot:title>
                <p>{{ $t('production') }}</p>
              </template>
              <template v-slot:links>
                <router-link
                to="/item-configurations"
                  v-show="$checkPermission('views-configurations')"
                >
                  {{ $t('item-configurations') }}
                </router-link>
                <router-link
                  to="/production-documents"
                  v-show="$checkPermission('views-production-documents')"
                >
                  {{ $t('production-doc') }}
                </router-link>
                <router-link
                  to="/data-collection"
                  v-show="$checkPermission('create-production-document')"
                >
                  {{ $t('production-data-collection') }}
                </router-link>
                <!-- <router-link
                  to="/carts"
                  v-show="$checkPermission('create-production-document')"
                >
                  Cart Data Collection
                </router-link> -->
              </template>
            </dashboard-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DashboardCard from '@/components/base/DashboardCard.vue';

export default {
  components: {
    DashboardCard,
  },
};
</script>
