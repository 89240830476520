<template>
  <div class="flex justify-center">
    <button class="border-2 border-aitmes-purple text-aitmes-purple hover:ring-4 hover:dark:ring-0
    ring-aitmes-purple ring-opacity-25 px-4 py-2 rounded-lg font-bold dark:text-white
    transition-colors duration-300 hover:dark:bg-aitmes-purple hover:dark:text-slate-700"
    type="submit">
      <slot/>
    </button>
  </div>
</template>
