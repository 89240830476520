<template>
  <div class="w-full flex flex-col items-center">
      <p class="text-3xl lg:text-5xl font-bold my-6 dark:text-white"> {{cart.description}} </p>
      <cart-rows :cartRows="cart.cart_rows" :colNumber="cart.columns" :rowNumber="cart.rows"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CartRows from '../components/carts/CartRows.vue';

export default {
  components: { CartRows },
  data() {
    return {
      cart: {},
    };
  },
  async created() {
    this.cart = await this.getCart(this.$route.params.id);
  },
  methods: {
    ...mapActions('carts', ['getCart']),
  },
};
</script>
