import axios from 'axios';

export default {
  namespaced: true,

  state: {
    companySettings: [],
  },

  getters: {
    companySettings: (state) => state.companySettings,
  },

  mutations: {
    setCompanySettings(state, companySettings) {
      state.companySettings = companySettings;
    },
  },

  actions: {
    async getCompanySettings({ commit }) {
      const { data } = await axios.get('/api/company-settings');

      commit('setCompanySettings', data);
    },

    async putCompanySetting({ dispatch }, { id, data }) {
      await axios.put(`/api/company-settings/${id}`, data);

      dispatch('getCompanySettings');
    },
  },

};
