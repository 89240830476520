<template>
  <form @submit.prevent="addRole" class="flex flex-col items-center">
    <div class="flex flex-col w-10/12 md:w-1/2">
      <label for="slug" class="flex flex-col my-2 text-left">
        {{ $t('name') }}
        <input
          type="text"
          name="slug"
          id="slug"
          v-model="role.name"
          required
        />
      </label>
    </div>
    <div class="flex flex-col justify-center items-center my-2 mx-6">
      <p>{{ $t('select-permission') }}</p>
      <div class="grid sm:grid-cols-2 sm:gap-x-24 w-fit">
        <label
          v-for="permission in permissions"
          :key="permission.id"
          :for="permission.getNameOption"
          class="flex flex-row-reverse justify-end my-2 text-left text-sm md:text-base"
        >
          {{ permission.name }}
          <input
            type="checkbox"
            :name="permission.name"
            :id="permission.name"
            :value="permission.name"
            v-model="role.permissions"
          />
        </label>
      </div>
    </div>
    <submit-button>
      {{ $t('add-role') }}
    </submit-button>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SubmitButton from '../base/SubmitButton.vue';

export default {
  components: {
    SubmitButton,
  },
  data() {
    return {
      role: {
        name: '',
        permissions: [],
      },
    };
  },
  computed: {
    ...mapGetters('permissions', ['permissions']),
  },
  methods: {
    ...mapActions({
      getPermissions: 'permissions/getPermissions',
      postRole: 'permissions/postRole',
    }),
    addRole() {
      this.postRole(this.role);
      this.$emit('role-has-been-added');
    },
  },
  created() {
    this.getPermissions();
  },
};
</script>
