<template>
  <form @submit.prevent="updateUser" class="flex flex-col items-center my-2">
    <div class="flex flex-col w-10/12 md:w-2/3">
      <label for="name" class="flex flex-col my-2 text-left">
        {{ $t('name') }}
        <input
          type="text"
          name="name"
          id="name"
          v-model="editableUser.name"
          required
        />
      </label>
      <label for="email" class="flex flex-col my-2 text-left">
        {{ $t('e-mail') }}
        <input
          type="email"
          name="email"
          id="username"
          v-model="editableUser.email"
          required
        />
      </label>
      <div class="flex flex-col justify-center items-center my-2 mx-6">
        <p>{{ $t('enable') }}</p>
        <div class="grid sm:grid-cols-2 sm:gap-x-24 w-fit">
          <label
            v-for="company in companies"
            :key="company.id"
            :for="company.slug"
            class="flex flex-row-reverse justify-end my-2 text-left text-sm md:text-base"
          >
            {{ company.name }}
            <input
              type="checkbox"
              :name="company.slug"
              :id="company.slug"
              :value="company.id"
              v-model="checkedCompanies"
            />
          </label>
        </div>
      </div>
      <div class="flex flex-col justify-center items-center my-2 mx-6">
        <p>Granted to this Roles in the Current Company (at least one)</p>
        <div class="grid sm:grid-cols-2 sm:gap-x-24 w-fit">
          <label
            v-for="role in roles"
            :key="role.id"
            :for="role.name"
            class="flex flex-row-reverse justify-end my-2 text-left text-sm md:text-base"
          >
            {{ role.name }}
            <input
              type="checkbox"
              :name="role.name"
              :id="role.name"
              :value="role.name"
              v-model="checkedRoles"
            />
          </label>
        </div>
      </div>
      <submit-button>
          {{ $t('update-user') }}
      </submit-button>
    </div>
  </form>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import SubmitButton from '../base/SubmitButton.vue';

export default {
  components: {
    SubmitButton,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editableUser: {
        name: this.user.name,
        email: this.user.email,
      },
      checkedCompanies: [],
      checkedRoles: [],
    };
  },
  computed: {
    ...mapGetters({
      companies: 'companies/companies',
      roles: 'permissions/roles',
    }),
  },
  created() {
    this.getCompanies();
    this.fetchUserCompanies();
    this.getRoles();
    this.fetchUserRoles();
  },
  methods: {
    ...mapActions({
      patchUser: 'users/patchUser',
      getCompanies: 'companies/getCompanies',
      getUserCompanies: 'users/getUserCompanies',
      postAttachCompanies: 'users/postAttachCompanies',
      getRoles: 'permissions/getRoles',
      getUserRoles: 'users/getUserRoles',
      postAttachRoles: 'users/postAttachRoles',
    }),
    async fetchUserCompanies() {
      const companiesList = await this.getUserCompanies(this.user.username);
      this.checkedCompanies = companiesList.map(({ id }) => id);
    },
    async fetchUserRoles() {
      const rolesList = await this.getUserRoles(this.user.id);
      this.checkedRoles = rolesList.map((role) => role.name);
    },
    updateUser() {
      this.patchUser({ id: this.user.id, data: this.editableUser });
      this.postAttachCompanies({ id: this.user.id, companiesList: this.checkedCompanies });
      this.postAttachRoles({ id: this.user.id, roles: this.checkedRoles });
      this.$emit('user-has-been-updated');
    },
  },
};
</script>
