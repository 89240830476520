<template>
    <div class="flex flex-col justify-center items-center">
      <h1 class="text-3xl lg:text-5xl font-bold my-6 text-aitmes-blue dark:text-white">
        Select a cart
      </h1>
      <div class="grid grid-flow-col">
        <router-link :to="{ path: '/carts/'+ cart.id }" v-for="cart in carts" :key="cart.id">
          <button
          class="m-5 rounded-lg px-6 py-2 border-2 border-aitmes-blue text-aitmes-blue font-bold
          md:mb-0 hover:bg-aitmes-blue hover:text-white uppercase transition-color duration-500
          dark:border-aitmes-purple dark:text-white hover:dark:bg-aitmes-purple
          hover:dark:text-slate-700">
              {{ cart.description }}
          </button>
        </router-link>
      </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('carts', ['carts']),
  },
  created() {
    this.getCarts();
  },
  methods: {
    ...mapActions('carts', ['getCarts']),
  },
};
</script>
