<!-- eslint-disable -->
<template>
  <div class="w-full">
    <ul class="w-full mx-auto flex flex-wrap justify-center">
      <li v-for="companySetting in companySettings" :key="companySetting.id" class="flex-initial basis-2/3 md:basis-1/3
      xl:basis-1/4 shadow-lg bg-white text-gray-600 m-4 p-4 rounded-xl
      border-t-8 border-t-aitmes-purple">
        <company-setting-row :companySetting="companySetting"></company-setting-row>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CompanySettingRow from '@/components/company-settings/CompanySettingRow.vue';

export default {
  computed: {
    ...mapGetters('companySettings', ['companySettings']),
  },
  methods: {
    ...mapActions('companySettings', ['getCompanySettings']),
  },
  created() {
    this.getCompanySettings();
  },
  components: {
    CompanySettingRow,
  },
};
</script>
