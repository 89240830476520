<template>
  <form @submit.prevent="updateDepartment" class="flex flex-col">
    <div class="grid grid-cols-2 justify-start my-4 mx-auto w-11/12 md:w-3/4">
      <label for="name" class="flex flex-col m-2 text-left">
        {{ $t('department-name') }}
        <input
          type="text"
          name="name"
          id="name"
          v-model="editableDepartment.name"
          required
        />
      </label>
      <label for="slug" class="flex flex-col m-2 text-left">
        {{ $t('slug') }}
        <input
          type="text"
          name="slug"
          id="slug"
          v-model="editableDepartment.slug"
        />
      </label>
      <label for="panthera_dep_code" class="flex flex-col m-2 text-left">
        {{ $t('panthera-dep-code') }}
        <input
          type="text"
          name="panthera_dep_code"
          id="panthera_dep_code"
          v-model="editableDepartment.panthera_dep_code"
        />
      </label>
    </div>
    <submit-button>
      {{ $t('update-department.btn') }}
    </submit-button>
  </form>
</template>

<script>

import { mapActions } from 'vuex';
import SubmitButton from '../base/SubmitButton.vue';

export default {
  components: {
    SubmitButton,
  },
  props: {
    department: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editableDepartment: {
        name: this.department.name,
        slug: this.department.slug,
        panthera_dep_code: this.department.panthera_dep_code,
        company: this.department.company,
      },
    };
  },
  methods: {
    ...mapActions('departments', ['putDepartment']),
    updateDepartment() {
      this.putDepartment({ id: this.department.id, data: this.editableDepartment });
      this.$emit('department-has-been-updated');
    },
  },
};
</script>
