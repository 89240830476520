import { createApp } from 'vue';
import Toast, { POSITION, useToast } from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import checkPermission from './plugins/check-permission';

import './css/app.css';
import i18n from './i18n';

const toast = useToast();

axios.defaults.withCredentials = true;

const toastOptions = {
  icon: false,
  position: POSITION.BOTTOM_LEFT,
};

const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(Toast, toastOptions)
  .use(checkPermission)
  .mount('#app');

// TODO: [AA30-42] we could review what codes must be managed
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status < 308) return; // ignore redirects
    if (error.response.status === 401
        || error.response.status === 403) { // not authorized or forbidden
      toast.error(app.$t('api-errors.not-authorized'));
      router.push('/');
    } else { // Notify customer and throw error
      toast.error(error.response.data.message);
      store.state.errors = error.response.data.errors;
    }
    throw error;
  },
);
