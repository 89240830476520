<!--eslint-disable max-len-->
<template>
  <div class="flex justify-end">
    <div class="w-11/12">
      <custom-modal ref="addConfiguration">
          <template v-slot:header>
            <h1>{{ $t('add-config.title') }}</h1>
          </template>
          <template v-slot:body>
            <add-configuration @config-has-been-added="onConfigurationHasBeenAdded"></add-configuration>
          </template>
        </custom-modal>
        <div class="flex flex-row w-2/3 mx-auto mt-16 relative items-center justify-between md:justify-center">
          <h1 class="text-3xl font-bold text-aitmes-blue w-2/3 md:w-full text-left md:text-center">
            {{ $t('conf-list') }}
          </h1>
          <div class="w-full flex justify-end absolute">
            <button
              class="rounded-lg bg-aitmes-blue p-2 bg-opacity-75
              hover:bg-opacity-100"
              @click="$refs.addConfiguration.openModal"
            >
              <svg class="w-4 h-4 md:w-6 md:h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                <g id="Group_84" data-name="Group 84" transform="translate(-4600 -2164)">
                  <line id="Line_7" data-name="Line 7" x2="25" transform="translate(4601.5 2177.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                  <line id="Line_8" data-name="Line 8" x2="25" transform="translate(4614.5 2165.5) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                </g>
              </svg>
            </button>
          </div>
        </div>
        <br/>
      <item-configurations></item-configurations>
    </div>
  </div>
</template>

<script>
import ItemConfigurations from '@/components/item-configurations/ItemConfigurationsList.vue';
import AddConfiguration from '@/components/item-configurations/AddItemConfiguration.vue';
import CustomModal from '@/components/base/CustomModal.vue';

export default {
  components: { ItemConfigurations, AddConfiguration, CustomModal },
  methods: {
    onConfigurationHasBeenAdded() {
      this.$refs.addConfiguration.closeModal();
      this.toast.success(this.$t('add-conf-alert'));
    },
  },
};
</script>
