export default {
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The secrets of your production unveiled"])},
  "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aitmes® is another fine product of The Nonsense Factory 2015 - "])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["production"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])},
  "add-company": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new company"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add company"])}
  },
  "update-company": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit company"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update company"])}
  },
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
  "companies-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies list"])},
  "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slug"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cap"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "e-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "department-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department name"])},
  "add-department": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new department"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add department"])}
  },
  "update-department": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit department"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update department"])}
  },
  "panthera-dep-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panthera Dep Code"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "users-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users list"])},
  "add-new-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new user"])},
  "add-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
  "update-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update password"])},
  "update-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update user"])},
  "edit-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit user"])},
  "change-password-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password for user:"])},
  "add-new-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new role"])},
  "add-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add role"])},
  "roles-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles list"])},
  "update-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update role"])},
  "edit-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit role"])},
  "select-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions selection (at least one)"])},
  "password-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password confirmation"])},
  "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled on this companies (at least one)"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
  "part-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part number"])},
  "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial #"])},
  "action-serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action serial #"])},
  "select-dep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your department"])},
  "saved-panthera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved on Panthera?"])},
  "panthera-doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panthera doc #"])},
  "add-panthera-doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Panthera doc"])},
  "production-doc-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production documents' list"])},
  "scan-enter-item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the barcode or enter the item's details in the fields below"])},
  "departmens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departments"])},
  "production-doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production documents"])},
  "production-data-collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production data collection"])},
  "excel-export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel Export"])},
  "add-dep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new department"])},
  "dep-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departments list"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "update-dep-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department Has Been Updated"])},
  "add-dep-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department Has Been Added!"])},
  "update-company-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Has Been Updated"])},
  "add-company-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Has Been Added!"])},
  "update-production-doc-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production Document Row Updated"])},
  "add-panthera-doc-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panthera Document # Added"])},
  "update-role-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role Has Been Updated"])},
  "add-role-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role Has Been Added"])},
  "update-user-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Has Been Updated"])},
  "add-user-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Has Been Added"])},
  "update-password-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Has Been Changed"])},
  "update-row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update row"])},
  "add-production-doc-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production Document has been created!"])},
  "api-errors": {
    "not-authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Authorized"])}
  },
  "company-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Settings"])},
  "item-configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Configurations"])},
  "update-config": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit configuration"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit configuration"])}
  },
  "config_cod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration code"])},
  "config_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item code"])},
  "update-con-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration Has Been Updated"])},
  "add-config": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new configuration"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new configuration"])}
  },
  "conf-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurations list"])},
  "item_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item ID"])},
  "management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["management"])},
  "update-setting": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Setting"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Setting"])}
  },
  "company-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting"])},
  "company-setting-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Value"])},
  "update-company-setting-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting Has Been Updated"])},
  "departments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departments"])}
}