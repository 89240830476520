<template>
  <form @submit.prevent="updateCompany" class="flex flex-col">
    <div class="grid grid-cols-2 justify-start my-4 mx-auto w-11/12 md:w-3/4">
      <label for="name" class="flex flex-col m-2 text-left">
        {{ $t('company-name') }}
        <input
          type="text"
          name="name"
          id="name"
          v-model="editableCompany.name"
          required
        />
      </label>
      <label for="address" class="flex flex-col m-2 text-left">
        {{ $t('address') }}
        <input
          type="text"
          name="address"
          id="address"
          v-model="editableCompany.address"
        />
      </label>
      <label for="city" class="flex flex-col m-2 text-left">
        {{ $t('city') }}
        <input
          type="text"
          name="city"
          id="city"
          v-model="editableCompany.city"
        />
      </label>
      <label for="cap" class="flex flex-col m-2 text-left">
        {{ $t('cap') }}
        <input
          type="text"
          name="cap"
          id="cap"
          v-model="editableCompany.cap"
        />
      </label>
      <label for="state" class="flex flex-col m-2 text-left">
        {{ $t('state') }}
        <input
          type="text"
          name="state"
          id="state"
          v-model="editableCompany.state"
        />
      </label>
      <label for="country" class="flex flex-col m-2 text-left">
        {{ $t('country') }}
        <input
          type="text"
          name="country"
          id="country"
          v-model="editableCompany.country"
        />
      </label>
      <label for="email" class="flex flex-col m-2 text-left">
        {{ $t('e-mail') }}
        <input
          type="email"
          name="email"
          id="email"
          required
          v-model="editableCompany.email"
        />
      </label>
    </div>
    <submit-button>
      {{ $t('update-company.btn') }}
    </submit-button>
  </form>
</template>

<script>

import { mapActions } from 'vuex';
import SubmitButton from '../base/SubmitButton.vue';

export default {
  components: {
    SubmitButton,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editableCompany: {
        name: this.company.name,
        address: this.company.address,
        cap: this.company.cap,
        city: this.company.city,
        state: this.company.state,
        country: this.company.country,
        email: this.company.email,
      },
    };
  },
  methods: {
    ...mapActions('companies', ['patchCompany']),
    updateCompany() {
      this.patchCompany({ id: this.company.id, data: this.editableCompany });
      this.$emit('company-has-been-updated');
    },
  },
};
</script>
