<!-- eslint-disable max-len -->
<template>
  <div class="w-11/12 xl:w-full">
    <custom-modal ref="editUser">
      <template v-slot:header>
        <h1>{{ $t('edit-user') }} ({{ user.username }})</h1>
      </template>
      <template v-slot:body>
        <edit-user :user="user" @user-has-been-updated="onUserHasBeenUpdated">
        </edit-user>
      </template>
    </custom-modal>
    <custom-modal ref="changePassword">
      <template v-slot:header>
        <h1>{{ $t('change-password-user') }} ({{ user.username }})</h1>
      </template>
      <template v-slot:body>
        <change-password :user-id="user.id" @password-has-been-changed="onPasswordHasBeenChanged">
        </change-password>
      </template>
    </custom-modal>
    <div class="grid sm:grid-cols-4 lg:grid-cols-12 justify-between items-center">
      <div class="grid lg:grid-cols-3 col-span-3 lg:col-span-10 text-left w-5/6">
        <p>{{ user.name }}</p>
        <p>{{ user.username }}</p>
        <p>{{ user.email }}</p>
      </div>
      <div class="col-span-1 lg:col-span-2 my-auto flex justify-end">
        <button
          class="rounded p-2 bg-aitmes-purple bg-opacity-75 hover:bg-opacity-100
        text-white m-2"
          @click="$refs.changePassword.openModal"
        >
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 72.763 72.763">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_127" data-name="Rectangle 127" width="70" height="32.903" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="4"/>
              </clipPath>
            </defs>
            <g id="Group_90" data-name="Group 90" transform="translate(23.266 4.242) rotate(45)">
              <g id="Group_89" data-name="Group 89" transform="translate(-3 -3)" clip-path="url(#clip-path)">
                <path id="Path_34" data-name="Path 34" d="M59.717,9.525H28.532a14.392,14.392,0,1,0,0,11.729l7.194,0a1.921,1.921,0,0,0,.99-.274c1.466-.882,3.254-3.838,4.719-4.719.618-.372.76-.043,1.372.338,1.277.8,2.9,4.41,4.172,5.206.638.4.957.964,1.583.549,1.273-.844,3.372-3.8,4.645-4.645.667-.442,1.052-.641,1.7-.17,1.228.891,2.513,2.85,3.741,3.741.332.24.955,1.367,1.364,1.364l.98.058c.517,0,1.269-1.254,1.627-1.627.96-1,2.522-3.5,3.993-5.032a.959.959,0,0,0-.078-1.4L60.949,9.972A1.92,1.92,0,0,0,59.717,9.525Z" transform="translate(1.06 1.06)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="4"/>
                <circle id="Ellipse_7" data-name="Ellipse 7" cx="3.5" cy="3.5" r="3.5" transform="translate(8.994 12.395)" fill="#fff"/>
              </g>
            </g>
          </svg>
        </button>
        <button
          class="rounded p-2 bg-aitmes-purple bg-opacity-75 hover:bg-opacity-100
        text-white m-2"
          @click="$refs.editUser.openModal"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23.389 26.428">
            <g id="Group_99" data-name="Group 99" transform="translate(19.285 -0.001) rotate(40)">
              <g id="Union_12" data-name="Union 12" transform="translate(-0.002 4.82)" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path d="M.018,19.736H0V19.7l0,0H0V0H5.36V19.713h0l0,.01v.013H5.354L2.681,25.18Z" stroke="none"/>
                <path d="M 2.684532642364502 20.63460159301758 L 3.360268831253052 19.25786018371582 L 3.360268831253052 2.000004053115845 L 2.001998901367188 2.000004053115845 L 2.001998901367188 19.23925399780273 L 2.684532642364502 20.63460159301758 M 2.681468963623047 25.18000411987305 L 0.01833889074623585 19.73559379577637 L 0.001998891122639179 19.73559379577637 L 0.001998891122639179 3.967285010730848e-06 L 5.360269069671631 3.967285010730848e-06 L 5.360269069671631 19.71271324157715 L 5.364938735961914 19.71272468566895 L 5.360269069671631 19.72223472595215 L 5.360269069671631 19.73559379577637 L 5.353708744049072 19.73559379577637 L 2.681468963623047 25.18000411987305 Z" stroke="none" fill="#fff"/>
              </g>
              <g id="Rectangle_80" data-name="Rectangle 80" transform="translate(0.001)" fill="#fff" stroke="#fff" stroke-width="1">
                <path d="M1,0H4.356a1,1,0,0,1,1,1V3.856a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1A1,1,0,0,1,1,0Z" stroke="none"/>
                <path d="M1,.5H4.356a.5.5,0,0,1,.5.5V3.356a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none"/>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import CustomModal from '@/components/base/CustomModal.vue';
import EditUser from '@/components/users/EditUser.vue';
import ChangePassword from '@/components/users/ChangePassword.vue';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  components: {
    CustomModal,
    EditUser,
    ChangePassword,
  },
  methods: {
    onUserHasBeenUpdated() {
      this.$refs.editUser.closeModal();
      this.toast.success(this.$t('update-user-alert'));
    },
    onPasswordHasBeenChanged() {
      this.$refs.changePassword.closeModal();
      this.toast.success(this.$t('update-password-alert'));
    },
  },
};
</script>
