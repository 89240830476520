import axios from 'axios';

export default {
  namespaced: true,

  state: {
    departments: [],
  },

  getters: {
    departments: (state) => state.departments,
  },

  mutations: {
    setDepartments(state, departments) {
      state.departments = departments;
    },
  },

  actions: {
    async getDepartments({ commit }) {
      const { data } = await axios.get('/api/departments');

      commit('setDepartments', data);
    },
    async postDepartment({ dispatch }, department) {
      await axios.post('/api/departments', department);

      dispatch('getDepartments');
    },
    async putDepartment({ dispatch }, { id, data }) {
      await axios.put(`/api/departments/${id}`, data);

      dispatch('getDepartments');
    },
  },
};
