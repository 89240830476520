<template>
  <div class="my-3 w-fit flex flex-col items-center">
    <form @submit.prevent="submit">
      <div v-for="cartRow in cartRows" :key="cartRow.id" class="flex mb-7 justify-start w-full">
            <cart-box :cartBoxes="cartRow.cart_boxes" :colNumber="colNumber" :rowNumber="rowNumber"/><!--eslint-disable-line-->
      </div>
      <div class="flex w-full justify-end mb-5">
          <button class="rounded-md xl:rounded-lg px-3 py-1 xl:px-6 xl:py-2 border-2
          border-aitmes-blue text-aitmes-blue text-sm lg:text-base font-bold lg:mb-0
          hover:bg-aitmes-blue hover:text-white uppercase transition-color duration-500
          dark:border-aitmes-purple dark:text-white hover:dark:bg-aitmes-purple
          hover:dark:text-slate-700">
              Save
          </button>
      </div>
    </form>
  </div>
</template>

<script>
import cartBox from '@/components/carts/cartBox.vue';

export default {
  props: ['cartRows', 'colNumber', 'rowNumber'],
  components: { cartBox },
  methods: {
    submit() {
      this.$router.push({ name: 'cartSelect' });
    },
  },
};
</script>
