/* eslint-disable */
export default {
  // called by Vue.use(FirstPlugin)
  install(app, options) {
    // define instance method 
    app.config.globalProperties.$checkPermission = function (permission) {
      if (permission && this.$store.state.auth.user) {
        if (this.$store.getters['auth/isSuperAdmin']) {
          return true;
        }

        return this.$store.getters['auth/userPermissions'].some(element => element === permission);
      }
      return false;
    };
  },
};
