<template>
  <div class="md:mx-auto flex flex-col items-center w-8/12 xl:w-5/12">
    <role-row
      v-for="role in roles"
      :key="role.id"
      :role="role"
      class="shadow-lg bg-white text-gray-600 m-2 p-2 md:py-2 md:px-6 rounded-xl
      border-t-4 border-t-aitmes-purple dark:bg-slate-600 dark:text-white"
    >
    </role-row>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import RoleRow from '@/components/roles/RoleRow.vue';

export default {
  computed: {
    ...mapGetters('permissions', ['roles']),
  },
  components: {
    RoleRow,
  },
  methods: {
    ...mapActions({
      getRoles: 'permissions/getRoles',
    }),
  },
  created() {
    this.getRoles();
  },
};
</script>
